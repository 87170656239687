export const catchRequest = async (request, data) => {
  try {
    let response = null;
    if (data) {
      response = await request(data);
    } else {
      response = await request();
    }

    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const catchRequestWithParams = async (request, params, data) => {
  try {
    let response = null;
    if (data) {
      response = await request(params, data);
    } else {
      response = await request(params);
    }
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};
