import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import CustomizedMenus from "./menu";

import api from "../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Blogs = () => {
  const navigation = useNavigate();

  const tableRef = useRef(null);
  const [Blogs, setBlogs] = useState();

  useEffect(() => {
    GetBlogs();
  }, []);

  const GetBlogs = () => {
    api
      .GetBlogs()
      .then((res) => {
        console.log("==>>", res);
        setBlogs(res.data.blogs);
        // setAppointments(res.data)
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleActiveStatus = (data) => {
    console.log("here", data);
    api
      .blogActiveStatus(data.id)
      .then((res) => {
        console.log("==>>", res);
        GetBlogs();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <button
        className="btn btn-warning btn-sm float-end"
        onClick={() => navigation("/admin/CreateBlogs")}
      >
        + Add Blogs{" "}
      </button>

      <TableContainer component={Paper} ref={tableRef}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Title</StyledTableCell>
              <StyledTableCell align="center">Author</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Blogs &&
              Blogs.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.title}</StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    {row.author_name}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.date).getDate() +
                      ", " +
                      new Date(row.date).toLocaleString("default", {
                        month: "long",
                      }) +
                      "-" +
                      new Date(row.date).getFullYear()}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {row.isActive == 1 ? (
                      <span className="bg-success text-white rounded px-1">
                        Active
                      </span>
                    ) : (
                      <span className="bg-danger text-white rounded px-1">
                        In-Active
                      </span>
                    )}{" "}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomizedMenus
                      data={row}
                      handleActiveStatus={handleActiveStatus}
                    />
                    {/* Edit */}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Blogs;
