import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import CustomizedMenus from './menu';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Paper } from '@mui/material';
import api from '../../API/api';
import CardMedia from '@mui/material/CardMedia';

import Modal from '../../components/modal/modal';
import ViewProfile from './viewProfile';
import Swal from 'sweetalert2';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    [`&.${tableRowClasses.root}`]: {
        height: "10px",
        padding: 0,
        margin: 0,
    },

}));
export default function SharedProfiles() {

    const tableRef = useRef(null);

    const [data, setData] = useState([]);
    const [profilesData, setProfilesData] = useState([]);
    const [open, setOpen] = useState(false)
    const [loader1, setLoader1] = useState(false);
    const [newData, setNewData] = useState();
    const [search, setSearch] = useState();
    const [searchData, setSearchData] = useState();
    const [selectedData, setSelectedData] = useState();
    const [slag, setSlag] = useState();

    const [isAdd, setIsAdd] = useState(false)


    const getClientProfile = () => {
        api.getClientProfile().then((res) => {
            console.log("resposne", res);
            setData(res.data.data)
            setSearchData(res.data.data.filter((e) => e.isClient === 'Yes'))
            setLoader1(false)
        })
            .catch((err) => {
                console.log("err", err);
            })
    }

    useEffect(() => {
        getClientProfile();
    }, []);


    const handleCheck = (p) => {
        console.log(p);
        // setData(data.map((e) => (e.id === p.id ? { ...e, isSelect: !e.isSelect } : e)))
    }


    const sharedProfiles = (id) => {
        console.log("==>>", id);
        setNewData();
        setSlag(id);
        api.sharedProfiles(id).then((res) => {
            console.log("res", res);
            setProfilesData(res.data.profiles);
            setOpen(true)
        })
            .catch((err) => {
                console.log("err", err);
            })
    }


    const handleSelectedFor = (data) => {
        // setSearchData(searchData && searchData.map((e) => (e.id === data.id ? { ...e, selected: !e.selected } : e)));
        setSelectedData(searchData && searchData.map((e) => (e.id === data.id ? { ...e, selected: !e.selected } : e)))
    }

    const searchProfile = (s) => {
        console.log(s);
        setLoader1(true)
        api.searchProfile(s).then((res) => {
            console.log("result==>", res);
            setSearchData(res.data.search.map((e) => ({ ...e, selected: false })))
            setLoader1(false)
        })
            .catch((err) => {
                console.log("err", err);
                setLoader1(false)
            })
    }

    useEffect(() => {
        setLoader1(true)
        const delayDebounceFn = setTimeout(() => {
            if (search) {
                console.log("sea", search)
                searchProfile(search)
            }
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const handleAddMore = (e) => {
        const check = profilesData.some(obj => obj.id === e.id)
        console.log("=>", check);
        if (!check) {
            if (newData) {
                setNewData(pre => ([...pre, e]));
            }
            else {
                setNewData([e]);

            }
            setProfilesData(pre => ([...pre, e]));
        }

    }

    const handleSaveNewData = () => {
        console.log(newData);
        console.log(slag);
        console.log(profilesData);
        const obj = {
            newData: newData,
            slag: slag,
            profiles: profilesData
        }
        api.addNewProfile(obj).then((res) => {
            console.log('response', res);
            setOpen(false)
            Swal.fire({
                title: "Saved",
                text: "New Profile Added",
                icon: "success"
            });
        })
            .catch((err) => {
                console.log(err);
                setOpen(false)
                Swal.fire({
                    title: "Oppss",
                    text: "Something went wrong",
                    icon: "error"
                });
            })
    }

    const handleRemove = (e) => {
        console.log(e);

        if (newData) {
            // check = newData.some(obj => obj.id === e.id)
            setNewData(newData.filter(obj => obj.id !== e.id))
        }
        setProfilesData(profilesData.filter(obj => obj.id !== e.id))
        removeProfileFromShare(e)




    }

    const removeProfileFromShare = (e) => {
        console.log("==>>", e);
        // return;
        api.removeProfileFromShare(e).then((res) => {
            console.log("res", res);
        })
            .catch((err) => {
                console.log(err);
            })
    }


    const modatData = () => {
        return (
            <>
                <div className='border py-2'>
                    <ViewProfile type="admin" data={profilesData} handleRemove={handleRemove} />

                    <div>
                        {newData &&
                            <div className="text-center">
                                <button className='btn btn-warning' onClick={() => handleSaveNewData()}> + Save New Profiles</button>
                            </div>
                        }

                        {!isAdd &&
                            <div className="text-center">
                                <button className='btn btn-success' onClick={() => setIsAdd(true)}> + Add New Profiles</button>
                            </div>
                        }
                    </div>


                </div>

                {isAdd &&

                    <div className='border p-4'>
                        <div className="row">
                            <div className='text-end'>
                                <button className='btn btn-outlined' onClick={() => setIsAdd(false)}>x</button>
                            </div>
                            <div className="col-4">
                                <div>
                                    <div className='py-3'>
                                        <label htmlFor="">Search by Name or Phone Number </label>
                                    </div>
                                    <TextField
                                        id="standard-basic"
                                        label="For Whom?"
                                        variant="outlined"
                                        size="small"
                                        className="form-control"
                                        name="name"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                    />
                                </div>

                                <div>
                                    {searchData && searchData.map((e) => (
                                        <>
                                            {!loader1 ?
                                                <div className={` m-2 p-2 rounded ${e.selected ? 'bg-success text-white' : 'bg-light'} `} onClick={() => handleSelectedFor(e)} key={e.id}>
                                                    <b className='d-flex'>
                                                        {e.name}&nbsp; {e.isClient === 'Yes' &&
                                                            <div className='bg-success rounded text-white px-2 text-small'>Client</div>}
                                                    </b> {e.profession !== "null" && e.profession !== null && (e.profession) } {e.selected}

                                                </div >
                                                :
                                                <Box sx={{ width: 300 }}>
                                                    <Skeleton />
                                                    <Skeleton animation="wave" />
                                                    <Skeleton animation={false} />
                                                </Box>
                                            }
                                        </>
                                    ))}

                                    {searchData && searchData.length === 0 ?
                                        <>
                                            <div className='m-2 p-2'>
                                                No data found
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </div>
                            </div>

                            {selectedData &&
                                <div className="col-8 d-flex justify-content-center">
                                    {selectedData?.filter((e) => e.selected === true).map(e => (

                                        <div className="col-4">

                                            <Paper>
                                                <CardMedia
                                                    component="img"
                                                    height="150"
                                                    image={`${process.env.REACT_APP_BACKEND}img/profile/${e.image}`}
                                                    alt="Profile Photo"
                                                />
                                                <div className='m-1'>
                                                    <div className='text-center text-small'>
                                                        <b>
                                                            {e.name}
                                                        </b>
                                                    </div>
                                                    <div className='text-center text-small'>
                                                        Age: {e.age}
                                                    </div>
                                                    <div className='text-center text-small pb-2'>
                                                        Phone: {e.phone}
                                                    </div>

                                                </div>
                                                <div className='m-2 p-2 text-center'>
                                                    <button className='btn btn-warning btn-sm' onClick={() => handleAddMore(e)}>+ Add To Share </button>
                                                </div>
                                            </Paper>
                                        </div>
                                    ))}
                                </div>
                            }


                        </div>

                    </div >
                }


            </>
        )
    }


    const handleActiveStatus = (data) => {
        console.log("worked", data.id);
        api.sharedActiveStatus(data.id).then((res) => {
            console.log('=>', res);
            getClientProfile()
        })
            .catch((err) => {
                console.log("err", err);
            })
    }


    return (
        <>
            <Modal view={modatData()} open={open} onClose={() => setOpen(false)} size='lg' />

            <div className='text-center'>
                <h3> Shared Profiles </h3>
            </div>
            <TableContainer component={Paper} ref={tableRef}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>#</StyledTableCell>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>Uniques ID</StyledTableCell>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            <StyledTableCell align="center">Phone</StyledTableCell>
                            <StyledTableCell align="center">Email</StyledTableCell>
                            <StyledTableCell align="center">DOB</StyledTableCell>
                            <StyledTableCell align="center">City</StyledTableCell>
                            <StyledTableCell align="center">Link</StyledTableCell>
                            <StyledTableCell align="center">Passkey</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {data && data.map((row, index) => (
                            <StyledTableRow key={row.id} >
                                <StyledTableCell ><Checkbox checked={row.isSelect} onClick={() => handleCheck(row)} /></StyledTableCell>
                                <StyledTableCell component="th" scope="row" >
                                    {index + 1}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" >
                                    {row.unique_id}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center"> <a href={`tel:${row.phone}`}>{row.phone}</a> </StyledTableCell>
                                <StyledTableCell align="center">{row.email}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {new Date(row.dob).getDate()
                                        + ", " +
                                        new Date(row.dob).toLocaleString("default", { month: "long", })
                                        + "-" +
                                        new Date(row.dob).getFullYear()}
                                </StyledTableCell>

                                <StyledTableCell align="center">{row.current_city}</StyledTableCell>
                                <StyledTableCell align="center">{window.location.origin.toString()}/SharedLink/{row.slag}</StyledTableCell>
                                <StyledTableCell align="center"><div className='border'> {row.password} </div></StyledTableCell>
                                <StyledTableCell align="center"><div className='border'> {row.isActive === 1 ? <div className='bg-success'>Active</div> : <div className='bg-danger'>Deleted </div>} </div></StyledTableCell>
                                <StyledTableCell align="center">
                                    <CustomizedMenus
                                        type='sharedProfile'
                                        handleActiveStatus={handleActiveStatus}
                                        data={row}
                                        slag={row.slag}
                                        viewProfile={sharedProfiles}
                                    />
                                </StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )
}
