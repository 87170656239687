import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import api from '../../API/api';
import { TextField } from '@mui/material';





const columns = [
  {
    id: "sl.no",
    label: "Sl.No",
    render: (row, rowIndex) => rowIndex + 1,
    // filter: (value, cb) => cb(value),
  },
  {
    id: 'first_name',
    label: 'First Name',
    filter: (value, cb) => cb(value),
  },
  {
    id: 'last_name',
    label: 'Last Name',
    filter: (value, cb) => cb(value),
  },
  {
    id: 'email',
    label: 'Email',
    filter: (value, cb) => cb(value),
  },
  {
    id: 'phone',
    label: 'Phone',
    filter: (value, cb) => cb(value),
  },
];

export default function AdsLeads() {
  const [current_page, setCurrentPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [per_page, setPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [pagination, setPagination] = React.useState({
    current_page: current_page,
    per_page: per_page,
    total: total,
    initial_render: true,
  });

  const [filter, setFilter] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    search: '',
    initial_render: true
  });

  const FetchLeads = () => {
    // need to remvoe initial_render from filter
    let newFilter = { ...filter };

    delete newFilter.initial_render;

    // need to also remove any keys that have empty values or empty strings
    Object.keys(newFilter).forEach((key) => {
      if (newFilter[key] === '' || newFilter[key] === undefined) {
        delete newFilter[key];
      }
    });
    api
      .adsLeads({ current_page, per_page: per_page, ...newFilter })
      .then((res) => {
        console.log(res.data);

        if (res.data.success) {

          const resultData = res.data.onboarders;
          setData(resultData.data);
          setPagination({
            current_page: resultData.current_page,
            per_page: resultData.per_page,
            total: resultData.total,
            initial_render: true,
          })
        }
      })
      .catch((err) => {
        console.log(err, 'dajshv');
      });
  };


  React.useEffect(() => {
    FetchLeads();
  }, []);

  React.useEffect(() => {
    if (!pagination.initial_render) {
      FetchLeads();
    }
  },
    [pagination.current_page, pagination.per_page, pagination.initial_render]
  );

  React.useEffect(() => {
    FetchLeads();
  }, [filter])

  const onPageChange = (event, newPage) => {
    setPagination({
      current_page: newPage + 1,
      per_page: 10,
      total: 0,
      initial_render: false
    })
    setPerPage(10);
  };

  const onChangePerPage = (event) => {
    setPagination({
      current_page: 1,
      per_page: event.target.value,
      total: 0,
      initial_render: false
    })
  };

  const onChangeFilter = (event, column) => {
    setPagination({
      current_page: 1,
      per_page: 10,
      total: 0,
      initial_render: true
    });
    setFilter({
      ...filter,
      [column.id]: event.target.value,
    })
  };

  return <Box sx={{ width: '100%', height: '100%' }}>
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <TableHead>
            <TableRow>
              {
                columns && columns.map((column) => {
                  return (
                    <TableCell
                    >
                      {column.filter &&
                        <TextField
                          label={column.label}
                          variant="standard"
                          value={filter[column.id]}
                          onChange={(event) => {
                            onChangeFilter(event, column);
                          }}
                        >

                        </TextField>
                      }
                      {!column.filter && column.label}
                    </TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row, rowIndex) => {
              return <TableRow
                hover
                // onClick={(event) => handleClick(event, row.id)}
                // role="checkbox"
                // aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                // selected={isItemSelected}
                sx={{ cursor: 'pointer' }}

              >
                <TableCell
                  component="th"
                  id={rowIndex}
                  scope="row"
                  padding="none"
                  align='left'
                >
                  {rowIndex + 1}
                </TableCell>
                <TableCell align="left">{row.first_name}</TableCell>
                <TableCell align="left">{row.last_name}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={pagination.total}
        rowsPerPage={pagination.per_page}
        page={pagination.current_page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangePerPage}
      />
    </Paper>
    {/* <FormControlLabel
      control={<Switch checked={dense} onChange={handleChangeDense} />}
      label="Dense padding"
    /> */}
  </Box>
}
