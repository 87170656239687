import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useBasicLoader } from "../hooks/useBasicLoader";
import { catchRequest } from "../hooks/useCatchRequest";
import api from "../API/api";
import { useUserContext } from "./UserContext";

export const partnersTableHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "occupation",
    numeric: false,
    disablePadding: false,
    label: "Occupation",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export const partners2TableHead = [
  {
    id: "uid",
    numeric: false,
    disablePadding: true,
    label: "Partner ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    format: (value) => {
      return value === 1 ? "Active" : "Inactive";
    },
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Partner Since",
    isDate: true,
  },
  {
    id: "leads_count",
    numeric: false,
    disablePadding: false,
    label: "Leads Generated",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "occupation",
    numeric: false,
    disablePadding: false,
    label: "Occupation",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export const skeletonRows = [
  {
    id: 1,
    name: "ashdksadhw",
  },
  {
    id: 2,
    name: "ashdksadhw",
  },
  {
    id: 3,
    name: "ashdksadhw",
  },
  {
    id: 4,
    name: "ashdksadhw",
  },
  {
    id: 5,
    name: "ashdksadhw",
  },
];

export const optionsMenu = [
  {
    title: "View",
    path: (partner) => {
      return `/admin/partners/manage/${partner.uid}`;
    },
  },
  {
    title: "View Leads",
    path: (partner) => {
      return `/admin/partners/manage/${partner.uid}/leads`;
    },
  },
];

export const PartnersContext = createContext({
  partners: [],
  partnersPaginate: {},
  partnersFetch: (paginate) => {},
  partnersLoading: true,
  partnersTableHead: partners2TableHead,
  partnersFetchPaginate: (page, totalRows) => {},
  resetLoading: () => {},
  paginateOptions: {},
  optionsMenu: optionsMenu,
});

export const PartnersContextProvider = ({ children }) => {
  const { user } = useUserContext();

  const { loading, setLoading, resetLoading } = useBasicLoader(true);
  const { loading: partnerLoading, setLoading: setPartnerLoading } =
    useBasicLoader(true);

  const [partners, setPartners] = useState([]);
  const [partnersPaginate, setPartnersPaginate] = useState({});

  const isAdmin = useMemo(() => {
    return user && user.role === "admin";
  }, [user]);

  const partners2TableHead = useMemo(
    () => [
      {
        id: "uid",
        numeric: false,
        disablePadding: true,
        label: "Partner ID",
      },
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        format: (value) => {
          return value === 1 ? "Active" : "Inactive";
        },
      },
      {
        id: "created_at",
        numeric: false,
        disablePadding: false,
        label: "Partner Since",
        isDate: true,
      },
      {
        id: "leads_count",
        numeric: false,
        disablePadding: false,
        label: "Leads Generated",
      },
      {
        id: "phone",
        numeric: true,
        disablePadding: false,
        label: "Phone Number",
      },
      {
        id: "city",
        numeric: false,
        disablePadding: false,
        label: "City",
      },
      {
        id: "occupation",
        numeric: false,
        disablePadding: false,
        label: "Occupation",
      },
      isAdmin && {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
      },
    ],
    [user]
  );

  const partnerFetch = (partnerId) => {
    setPartnerLoading(true);
  };

  const partnersFetch = async (id) => {
    setLoading(true);

    let resultArray = [];

    if (user && user.role === "coordinator") {
      resultArray = await catchRequest(api.fetchPartners, {
        creator_id: user.uid,
      });
    } else {
      if (id) {
        resultArray = await catchRequest(api.fetchPartners, {
          creator_id: id,
        });
      } else {
        resultArray = await catchRequest(api.fetchPartners);
      }
    }

    let [response, error] = resultArray;
    if (error) {
      console.log("error", error);
    }

    // data is in shape of
    // response.data.partners
    // data.partners contains the pagination
    // and partners.data will cotnain the array of partners
    // partners.data[index].partnerDetails will contain the partner details
    if (response && response.data) {
      let newPartnersArray = [];

      response.data.partners.data.forEach((partner) => {
        let partnerObject = {};
        partnerObject.id = partner.id;
        partnerObject.uid = partner.partner_details.uid;
        partnerObject.status = partner.partner_details.status;
        partnerObject.name = partner.name;
        partnerObject.email = partner.email;
        partnerObject.phone = partner.partner_details.phone;
        partnerObject.occupation = partner.partner_details.occupation;
        partnerObject.city = partner.partner_details.city;
        partnerObject.pincode = partner.partner_details.pincode;
        partnerObject.address = partner.partner_details.address;
        partnerObject.created_at = partner.partner_details.created_at;
        partnerObject.leads_count = partner.leads_count;
        newPartnersArray.push(partnerObject);
      });

      setPartners(newPartnersArray);
      let data = response.data.partners;
      delete data.data;

      setPartnersPaginate(data);
      setLoading(false);
    }
  };

  const partnersFetchPaginate = async (page, totalRows) => {
    // if (partnersPaginate.current_page) {
    //   if (partnersPaginate.current_page === partnersPaginate.last_page) {
    //     setLoading(false);
    //     return;
    //   }
    // }
    setLoading(true);

    let requestParams = {
      page: page + 1,
      total_rows: totalRows,
    };

    if (user && user.role === "coordinator") {
      requestParams.creator_id = user.uid;
    }

    const [response, error] = await catchRequest(
      api.fetchPartners,
      requestParams
    );

    if (error) {
      console.log("error", error);
    }

    // data is in shape of
    // response.data.partners
    // data.partners contains the pagination
    // and partners.data will cotnain the array of partners
    // partners.data[index].partnerDetails will contain the partner details
    if (response && response.data) {
      let newPartnersArray = [];

      response.data.partners.data.forEach((partner) => {
        let partnerObject = {};
        partnerObject.id = partner.id;
        partnerObject.uid = partner.partner_details.uid;
        partnerObject.name = partner.name;
        partnerObject.status = partner.partner_details.status;
        partnerObject.email = partner.email;
        partnerObject.phone = partner.partner_details.phone;
        partnerObject.occupation = partner.partner_details.occupation;
        partnerObject.city = partner.partner_details.city;
        partnerObject.pincode = partner.partner_details.pincode;
        partnerObject.address = partner.partner_details.address;
        partnerObject.created_at = partner.partner_details.created_at;
        partnerObject.leads_count = partner.leads_count;
        newPartnersArray.push(partnerObject);
      });

      setPartners(newPartnersArray);
      let data = response.data.partners;
      delete data.data;

      setPartnersPaginate(data);
      setLoading(false);
    }
  };

  return (
    <PartnersContext.Provider
      value={{
        partners,
        partnersPaginate,
        partnersFetch,
        partnersLoading: loading,
        partnersTableHead: partners2TableHead,
        resetLoading,
        partnersFetchPaginate,
        paginateOptions: partnersPaginate,
        optionsMenu: optionsMenu,
      }}
    >
      {children}
    </PartnersContext.Provider>
  );
};

export const usePartnersContext = () => {
  const { resetLoading } = useContext(PartnersContext);

  useEffect(() => {
    return () => {
      resetLoading();
    };
  }, []);

  return useContext(PartnersContext);
};
