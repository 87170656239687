import { useState } from "react";

export default function useCreateForm(defaultForm) {
  const [form, setForm] = useState(defaultForm);

  const setFormValue = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const rawSetFormValue = setForm;

  return { form, setFormValue, rawSetFormValue };
}
