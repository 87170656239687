export const leadsTableHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "phone_number",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "need_profiles_for",
    numeric: false,
    disablePadding: false,
    label: "Need Profiles for",
  },
  {
    id: "age_of_prospect",
    numeric: true,
    disablePadding: false,
    label: "Age of Prospect",
  },
  {
    id: "search_status",
    numeric: false,
    disablePadding: false,
    label: "Search Status",
  },
  {
    id: "partner_remarks",
    numeric: false,
    disablePadding: false,
    label: "Partner Remarks",
  },
];

export const skeletonRows = [
  {
    id: 1,
    name: "ashdksadhw",
  },
  {
    id: 2,
    name: "ashdksadhw",
  },
  {
    id: 3,
    name: "ashdksadhw",
  },
  {
    id: 4,
    name: "ashdksadhw",
  },
  {
    id: 5,
    name: "ashdksadhw",
  },
];
