import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ScrollableContainer from "../../components/containers/ScrollableContainer";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import GridTextFieldGenerator from "../../components/textfield/GridTextFieldGenerator";
import useCreateForm from "../../hooks/useFormState";
import {
  catchRequest,
  catchRequestWithParams,
} from "../../hooks/useCatchRequest";
import api from "../../API/api";
import { useBasicLoader } from "../../hooks/useBasicLoader";
import LeadUpdateForm from "./lead.update.form";
import { toast } from "react-hot-toast";
import useFormErrors from "../../hooks/useFormError";

const LeadManagePage = () => {
  // get the lead id
  const params = useParams();

  const [leadData, setLeadData] = useState({});

  const { loading, setLoading } = useBasicLoader(true);

  const { form, setFormValue, rawSetFormValue } = useCreateForm({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    address: "",
    age: "",
    need_profile_for: "",
    search_status: "",
    partner_remarks: "",
    authenticate_lead: "",
    remarks_from_center: "",
    conversion_status: "",
    service_type: "",
    service_amount: "",
    coordinator_commission: "",
    partner_commission: "",
  });

  const { checkAndGenerateFormError, createFormErrors, formErrors } =
    useFormErrors(form);

  const leadId = params.leadId;

  const fetchSingleLead = async () => {
    const [response, error] = await catchRequest(api.getLeadById, leadId);

    console.log("response", response);

    if (response && response.data.success) {
      setLeadData(response.data.lead);

      Object.keys(form).forEach((key) => {
        if (!response.data.lead[key]) {
          rawSetFormValue((prev) => ({
            ...prev,
            [key]: "",
          }));
        } else {
          rawSetFormValue((prev) => ({
            ...prev,
            [key]: response.data.lead[key],
          }));
        }
      });
    }
    setLoading(false);
    console.log("error", error);
  };

  useEffect(() => {
    fetchSingleLead();
  }, [leadId]);

  const handleUpdate = async (e) => {
    const { hasErrors, localErrors } = checkAndGenerateFormError(form, {
      coordinator_commission: true,
      partner_commission: true,
      partner_remarks: true,
      remarks_from_center: true,
      service_amount: true,
      service_type: true,
    });

    const [response, error] = await catchRequestWithParams(
      api.updateLead,
      leadId,
      {
        ...form,
        id: leadId,
      }
    );

    console.log("response", response);

    if (response && response.data.success) {
      toast.success(response.data.message);
    }

    console.log("error", error);
    if (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Paper
      sx={{
        margin: "1rem",
        elevation: 3,
        padding: "1rem",
        minHeight: "80vh",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">Lead {leadData.name}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          justifyItems={"flex-end"}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="contained" onClick={handleUpdate}>
            Update
          </Button>
        </Grid>
      </Grid>

      <ScrollableContainer direction="column">
        {loading && <p>Loading...</p>}
        {!loading && (
          <LeadUpdateForm
            form={form}
            setFormValue={setFormValue}
            rawSetFormValue={rawSetFormValue}
          />
          // <>
          //   <Accordion defaultExpanded>
          //     <AccordionSummary
          //       expandIcon={<ExpandMoreIcon />}
          //       aria-controls="panel1-content"
          //       id="panel1-header"
          //     >
          //       Basic Info
          //     </AccordionSummary>
          //     <AccordionDetails>
          //       <GridTextFieldGenerator
          //         fields={[
          //           {
          //             id: "name",
          //             label: "Name",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.name,
          //             onChange: (value) => {
          //               setFormValue("name", value);
          //             },
          //           },
          //           {
          //             id: "phone",
          //             label: "Phone",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.phone,
          //             onChange: (value) => {
          //               setFormValue("phone", value);
          //             },
          //           },
          //           {
          //             id: "email",
          //             label: "Email",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.email,
          //             onChange: (value) => {
          //               setFormValue("email", value);
          //             },
          //           },
          //           {
          //             id: "city",
          //             label: "City",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.city,
          //             onChange: (value) => {
          //               setFormValue("city", value);
          //             },
          //           },
          //           {
          //             id: "state",
          //             label: "State",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.state,
          //             onChange: (value) => {
          //               setFormValue("state", value);
          //             },
          //           },
          //           {
          //             id: "address",
          //             label: "Address",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.address,
          //             onChange: (value) => {
          //               setFormValue("address", value);
          //             },
          //           },
          //         ]}
          //         form={form}
          //       ></GridTextFieldGenerator>
          //     </AccordionDetails>
          //   </Accordion>
          //   <Accordion>
          //     <AccordionSummary
          //       expandIcon={<ExpandMoreIcon />}
          //       aria-controls="panel2-content"
          //       id="panel2-header"
          //     >
          //       Additional Info
          //     </AccordionSummary>
          //     <AccordionDetails>
          //       <GridTextFieldGenerator
          //         fields={[
          //           {
          //             id: "need_profile_for",
          //             label: "Need Profile For",
          //             field_type: "select",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             defaultValue: "",
          //             value: form.need_profile_for,
          //             select: true,
          //             options: [
          //               {
          //                 key: "Daughter",
          //                 value: "daughter",
          //               },
          //               {
          //                 key: "Son",
          //                 value: "son",
          //               },
          //               {
          //                 key: "Self",
          //                 value: "self",
          //               },
          //             ],
          //             onChange: (value) => {
          //               setFormValue("need_profile_for", value);
          //             },
          //           },
          //           {
          //             id: "age",
          //             label: "Age",
          //             field_type: "number",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.age,
          //             onChange: (value) => {
          //               setFormValue("age", value);
          //             },
          //           },
          //           {
          //             id: "search_status",
          //             label: "Search Status",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.search_status,
          //             onChange: (value) => {
          //               setFormValue("search_status", value);
          //             },
          //           },
          //           {
          //             id: "partner_remarks",
          //             label: "Partner Remarks",
          //             field_type: "text",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.partner_remarks,
          //             onChange: (value) => {
          //               setFormValue("partner_remarks", value);
          //             },
          //           },
          //         ]}
          //         form={form}
          //       ></GridTextFieldGenerator>
          //     </AccordionDetails>
          //   </Accordion>
          //   <Accordion>
          //     <AccordionSummary
          //       expandIcon={<ExpandMoreIcon />}
          //       aria-controls="panel3-content"
          //       id="panel3-header"
          //     >
          //       Lead Status
          //     </AccordionSummary>
          //     <AccordionDetails>
          //       <GridTextFieldGenerator
          //         fields={[
          //           {
          //             id: "authenticate_lead",
          //             label: "Authenticate Lead",
          //             field_type: "select",
          //             xs: 12,
          //             md: 6,
          //             lg: 6,
          //             defaultValue: "",
          //             value: form.authenticate_lead,
          //             select: true,
          //             options: [
          //               {
          //                 key: "Verified By Call",
          //                 value: "verified_by_call",
          //               },
          //               {
          //                 key: "Pending Verification",
          //                 value: "pending_verification",
          //               },
          //               {
          //                 key: "No Response From Lead",
          //                 value: "no_response_from_lead",
          //               },
          //             ],
          //             onChange: (value) => {
          //               setFormValue("authenticate_lead", value);
          //             },
          //           },
          //           {
          //             id: "remarks_from_center",
          //             label: "Remarks From Center",
          //             field_type: "text",
          //             xs: 12,
          //             md: 6,
          //             lg: 6,
          //             value: form.remarks_from_center,
          //             onChange: (value) => {
          //               setFormValue("remarks_from_center", value);
          //             },
          //           },
          //           {
          //             id: "conversion_status",
          //             label: "Conversion Status",
          //             field_type: "select",
          //             xs: 12,
          //             md: 6,
          //             lg: 6,
          //             defaultValue: "",
          //             value: form.conversion_status,
          //             select: true,
          //             options: [
          //               {
          //                 key: "Pending",
          //                 value: "pending",
          //               },
          //               {
          //                 key: "In Process",
          //                 value: "in_process",
          //               },
          //               {
          //                 key: "Converted",
          //                 value: "converted",
          //               },
          //             ],
          //             onChange: (value) => {
          //               setFormValue("conversion_status", value);
          //             },
          //           },
          //           {
          //             id: "service_type",
          //             label: "Service Type",
          //             field_type: "select",
          //             xs: 12,
          //             md: 6,
          //             lg: 6,
          //             defaultValue: "",
          //             value: form.service_type,
          //             select: true,
          //             options: [
          //               {
          //                 key: "Classic",
          //                 value: "classic",
          //               },
          //               {
          //                 key: "Signature",
          //                 value: "signature",
          //               },
          //               {
          //                 key: "Royal",
          //                 value: "royal",
          //               },
          //             ],
          //             onChange: (value) => {
          //               setFormValue("service_type", value);
          //             },
          //           },
          //           {
          //             id: "service_amount",
          //             label: "Service Amount",
          //             field_type: "number",
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.service_amount,
          //             onChange: (value) => {
          //               setFormValue("service_amount", value);
          //             },
          //           },
          //           {
          //             id: "coordinator_commission",
          //             label: "Coordinator Commission (5%)",
          //             field_type: "number",
          //             disabled: true,
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.coordinator_commission,
          //             onChange: (value) => {
          //               setFormValue("coordinator_commission", value);
          //             },
          //           },
          //           {
          //             id: "partner_commission",
          //             label: "Partner Commission (10%)",
          //             field_type: "number",
          //             disabled: true,
          //             xs: 12,
          //             md: 4,
          //             lg: 4,
          //             value: form.partner_commission,
          //             onChange: (value) => {
          //               setFormValue("partner_commission", value);
          //             },
          //           },
          //         ]}
          //         form={form}
          //       ></GridTextFieldGenerator>
          //     </AccordionDetails>
          //   </Accordion>
          // </>
        )}
      </ScrollableContainer>
    </Paper>
  );
};

export default LeadManagePage;
