import { TableCell, TextField } from "@mui/material";
import React from "react";

const TableHeadMultiPurpose = ({ headCell }) => {
  return (
    <TableCell
      key={headCell.id}
      align={headCell.id === "actions" ? "center" : "left"}
      padding={headCell.disablePadding ? "none" : "normal"}
      sx={{ minWidth: headCell.minWidth ? headCell.minWidth : "100%" }}
    >
      <TableHeadOptionFilter headCell={headCell} />
    </TableCell>
  );
};

const TableHeadOptionFilter = ({ headCell }) => {
  if (headCell.editable) {
    return <TableHeadTextField headCell={headCell} />;
  }

  return headCell.label;
};

const TableHeadTextField = ({ headCell }) => {
  return (
    <TextField
      label={headCell.label}
      key={headCell.id}
      align={headCell.id === "actions" ? "center" : "left"}
      padding={headCell.disablePadding ? "none" : "normal"}
      // sx={{ width: headCell.minWidth ? headCell.minWidth : "100%" }}
    />
  );
};

export default TableHeadMultiPurpose;
