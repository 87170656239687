import React, { useState, useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import api from '../../API/api';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ScheduleData = () => {

    const tableRef = useRef(null);

    const [appointments, setAppointments] = useState();

    useEffect(() => {
        GetAppointments()
    }, [])

    const GetAppointments = () => {
        api.getSchedule()
            .then((res) => {
                console.log("==>>", res);
                setAppointments(res.data)
            })
            .catch((err) => {
                console.log(err.response);
            })

    }





    return (
        <>
            <DownloadTableExcel
                filename="Appointment List"
                sheet="Apollo"
                currentTableRef={tableRef.current}
            >

                <button className='btn btn-warning btn-sm'> Export excel </button>

            </DownloadTableExcel>

            <TableContainer component={Paper} ref={tableRef}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            <StyledTableCell align="center">Phone</StyledTableCell>
                            <StyledTableCell align="center">For Whom</StyledTableCell>
                            <StyledTableCell align="center">Date</StyledTableCell>
                            <StyledTableCell align="center">Time Slot</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appointments && appointments.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {index + 1}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center"> <a href={`tel:${row.phone}`}>{row.phone}</a> </StyledTableCell>
                                <StyledTableCell align="center">{row.forWhom}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* {row.date_selected} */}
                                    {new Date(row.date_selected).getDate()
                                        + ", " +
                                        new Date(row.date_selected).toLocaleString("default", { month: "long", })
                                        + "-" +
                                        new Date(row.date_selected).getFullYear()}
                                </StyledTableCell>

                                <StyledTableCell align="center">{row.time_slot}</StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}


export default ScheduleData;