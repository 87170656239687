import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { useBasicLoader } from "../../hooks/useBasicLoader";
import { catchRequest } from "../../hooks/useCatchRequest";
import api from "../../API/api";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const CommissionDeleteModal = ({ open, handleClose, data }) => {
  const { loading, setLoading } = useBasicLoader(false);

  const handleCommissionPay = async () => {
    setLoading(true);
    if (!data) return;
    console.log("what the data", data);
    const [response, error] = await catchRequest(api.payCommission, {
      commission_for_id: data[0].id,
    });

    console.log("response", response);

    if (response && response.data.success) {
      console.log("response", response);
      toast.success(response.data.message);
      setLoading(false);
    }

    if (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        if (loading) {
          return;
        } else {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Confirm Payment
        </Typography>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"flex-end"}
          paddingTop={5}
        >
          <Button
            variant="contained"
            onClick={handleCommissionPay}
            disabled={loading}
          >
            Yes
          </Button>
          <Button variant="outlined" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CommissionDeleteModal;
