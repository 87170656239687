import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  LeaderboardSharp as LeaderboardSharpIcon,
  BookOnline as BookOnlineIcon,
  Apartment as ApartmentIcon,
  Contacts as ContactsIcon,
  AddTask as AddTaskIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Handshake as HandshakeIcon,
  ManageAccounts as ManageAccountsIcon,
  People as PeopleIcon,
  Grading as GradingIcon,
  ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';

/**
 * AdminDrawer component for the admin dashboard sidebar.
 * 
 * @param {Object} props
 * @param {boolean} props.open2 - State to control the open/close state of the drawer.
 * @param {function} props.setOpen2 - Function to set the open2 state.
 * @param {boolean} props.open - State to control the open/close state of the Profile submenu.
 * @param {function} props.handleClick - Function to handle the click event for the Profile submenu.
 * @param {boolean} props.openPartners - State to control the open/close state of the Partners submenu.
 * @param {function} props.handleClickPartners - Function to handle the click event for the Partners submenu.
 * @param {boolean} props.openCoordinators - State to control the open/close state of the Coordinators submenu.
 * @param {function} props.handleClickCoordinators - Function to handle the click event for the Coordinators submenu.
 * @param {boolean} props.openLeads - State to control the open/close state of the Leads submenu.
 * @param {function} props.handleClickLeads - Function to handle the click event for the Leads submenu.
 * @param {function} props.logout - Function to handle the logout action.
 */
const AdminDrawer = ({
  open2,
  setOpen2,
  open,
  handleClick,
  openPartners,
  handleClickPartners,
  openCoordinators,
  handleClickCoordinators,
  openLeads,
  handleClickLeads,
  logout
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Toolbar>
        <div>
          {/* <img src="/logo.png" className='img-fluid' /> */}
          {/* ****************************************** */}
          {!open2 ? (
            <MenuIcon onClick={() => setOpen2(true)} />
          ) : (
            <h2>
              Jodi Perfect <MenuIcon onClick={() => setOpen2(false)} />
            </h2>
          )}
          {/* ******************************************           */}
        </div>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding onClick={() => navigate("/admin/dashboard")}>
          <ListItemButton>
            <ListItemIcon>
              <LeaderboardSharpIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => navigate("/admin/SignUpData")}>
          <ListItemButton>
            <ListItemIcon>
              <BookOnlineIcon />
            </ListItemIcon>
            <ListItemText>Sign Up Users</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => navigate("/admin/ScheduleData")}>
          <ListItemButton>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText>Schedule User</ListItemText>
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding onClick={() => navigate("/admin/Profiles")}>
              <ListItemButton>
                <ListItemIcon>
                  <ContactsIcon />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </ListItemButton>
            </ListItem> */}

        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <ContactsIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/Profiles")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Profile" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/SharedProfiles")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Shared Profiles" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickPartners}>
          <ListItemIcon>
            <HandshakeIcon />
          </ListItemIcon>
          <ListItemText primary="Partner" />
          {openPartners ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openPartners} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/partners/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Partners" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/partners/create")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Create Partners" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/partners/commissions")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Commissions" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickCoordinators}>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText primary="Coordinators" />
          {openCoordinators ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openCoordinators} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/coordinators/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Coordinators" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/coordinators/create")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Create Coordinators" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/coordinators/commissions")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Commissions" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickLeads}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Leads" />
          {openLeads ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openLeads} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/leads/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Leads" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItem disablePadding onClick={() => navigate("/admin/Blogs")}>
          <ListItemButton>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText>Blogs</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => navigate("/admin/adsLead")}>
          <ListItemButton>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText>Add Leads</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <a
            style={{ color: "black" }}
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1LnOlrfPWJ8oAn58borllI3YG73S6Ishj/edit?usp=sharing&ouid=108432051821802881918&rtpof=true&sd=true"
          >
            <ListItemButton>
              <ListItemIcon>
                <GradingIcon />
              </ListItemIcon>
              <ListItemText> Management Exel </ListItemText>
            </ListItemButton>
          </a>
        </ListItem>

        {/* There was commented code here section */}

        <ListItemButton disablePadding onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
      </List>
      <Divider />
    </div>
  );
};

export default AdminDrawer;