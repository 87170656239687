import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import useCreateForm from "../../hooks/useFormState";
import useFormErrors from "../../hooks/useFormError";
import toast from "react-hot-toast";
import api from "../../API/api";
import { catchRequest } from "../../hooks/useCatchRequest";

export default function CoordinatorCreatePage() {
  const { form, setFormValue } = useCreateForm({
    name: "",
    phone: "",
    city: "",
    email: "",
    password: "",
  });

  const { formErrors, createFormErrors, checkAndGenerateFormError } =
    useFormErrors();

  const handleSubmit = async () => {
    const { hasErrors, localErrors } = checkAndGenerateFormError(form);

    const [response, error] = await catchRequest(api.registerCoordinator, form);
    if (error) {
      toast.error(error.response.data.message.split(".")[0]);
    }

    if (response && response.data.success) {
      toast.success(response.data.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        // minWidth: "80vw",
        minHeight: "85vh",
        margin: 0,
        padding: 0,
        flex: 1,
      }}
    >
      <Paper elevation={5} sx={{ flex: 1, padding: "1rem" }}>
        <Typography variant="h3" sx={{ paddingY: "0.5rem" }}>
          Create Coordinator
        </Typography>
        <Grid container spacing={2} sx={{ paddingY: "0.8rem" }}>
          <Grid item xs={12} md={4}>
            <TextField
              required
              fullWidth
              id="name"
              label="Name"
              variant="outlined"
              onChange={(e) => setFormValue("name", e.target.value)}
              error={formErrors.name ? true : false}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              fullWidth
              id="phone"
              label="Phone"
              variant="outlined"
              onChange={(e) => setFormValue("phone", e.target.value)}
              error={formErrors.phone ? true : false}
              helperText={formErrors.phone}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              required
              id="city"
              label="City"
              variant="outlined"
              onChange={(e) => setFormValue("city", e.target.value)}
              error={formErrors.city ? true : false}
              helperText={formErrors.city}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              variant="outlined"
              onChange={(e) => setFormValue("email", e.target.value)}
              error={formErrors.email ? true : false}
              helperText={formErrors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="password"
              label="Password"
              variant="outlined"
              onChange={(e) => setFormValue("password", e.target.value)}
              error={formErrors.password ? true : false}
              helperText={formErrors.password}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction={"row"}
          justifyContent={"flex-end"}
        >
          <Grid
            item
            xs={12}
            md={4}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Button onClick={handleSubmit} variant="contained">
              Create
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
