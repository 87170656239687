import React, { useEffect } from "react";
import CustomTable from "../../components/table/CustomTable";

import { useCoordinatorContext } from "../../context/CoordinatorContext";

const CoordinatorsManagePage = () => {
  const {
    coordinators,
    coordinatorsPaginate,
    coordinatorsTableHead,
    coordinatorsFetch,
    coordinatorsLoading,
    coordinatorsFetchPaginate,
    paginateOptions,
    optionsMenu,
  } = useCoordinatorContext();

  useEffect(() => {
    coordinatorsFetch();
  }, []);

  return (
    <CustomTable
      rows={coordinators}
      tableHead={coordinatorsTableHead}
      title="Coordinators"
      loading={coordinatorsLoading}
      paginateFetch={coordinatorsFetchPaginate}
      paginateOptions={paginateOptions}
      optionsMenu={optionsMenu}
    ></CustomTable>
  );
};

export default CoordinatorsManagePage;
