import axios from "axios";
// import AuthUser from './token';
//
// const {token} = AuthUser();

let sessionStorageToken = null;

let AuthorizationHeader = "";

// check if localStorage has token
if (sessionStorage.getItem("token")) {
  sessionStorageToken = sessionStorage.getItem("token");
  sessionStorageToken = sessionStorageToken.slice(1, -1);
  AuthorizationHeader = `Bearer ${sessionStorageToken}`;
}

const API = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  // baseURL: "https://jodiperfect.co/backend/public/api/",
  baseURL: process.env.REACT_APP_MAIN_API,

  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorageToken}`,
  },
});

export default API;
