import React, { useEffect } from "react";
import CustomTable from "../../components/table/CustomTable";
import { leadsTableHead } from "../../components/table/table_schema";
import { usePartnersContext } from "../../context/PartnersContext";

const PartnersManagePage = () => {
  const {
    partners,
    partnersPaginate,
    partnersTableHead,
    partnersFetch,
    partnersLoading,
    partnersFetchPaginate,
    paginateOptions,
    optionsMenu,
  } = usePartnersContext();

  useEffect(() => {
    partnersFetch();
  }, []);

  return (
    <CustomTable
      rows={partners}
      tableHead={partnersTableHead}
      title="Partners"
      loading={partnersLoading}
      paginateFetch={partnersFetchPaginate}
      paginateOptions={paginateOptions}
      optionsMenu={optionsMenu}
    ></CustomTable>
  );
};

export default PartnersManagePage;
