import { createContext, useContext, useEffect, useState } from "react";
import { useBasicLoader } from "../hooks/useBasicLoader";
import { catchRequest } from "../hooks/useCatchRequest";
import api from "../API/api";
import { useMUIModalState } from "../hooks/useMUIModalStates";
import CommissionDeleteModal from "./modals/CommissionDeleteModal";

export const commissionsTableHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "no_of_partners_listed",
    numeric: false,
    disablePadding: false,
    label: "No of Partners listed",
  },
  {
    id: "no_of_leads_shared",
    numeric: false,
    disablePadding: false,
    label: "No of Leads Shared",
  },
  {
    id: "no_of_conversions",
    numeric: false,
    disablePadding: false,
    label: "No of Conversions",
  },
  {
    id: "commission_due",
    numeric: false,
    disablePadding: false,
    label: "Commission Due",
  },
  {
    id: "payment_status",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export const skeletonRows = [
  {
    id: 1,
    name: "ashdksadhw",
  },
  {
    id: 2,
    name: "ashdksadhw",
  },
  {
    id: 3,
    name: "ashdksadhw",
  },
  {
    id: 4,
    name: "ashdksadhw",
  },
  {
    id: 5,
    name: "ashdksadhw",
  },
];

const optionsMenu = [
  {
    title: "Pay Commission",
    isButton: true,
    buttonAction: () => {
      // handleDeleteModalOpen();
    },
    path: () => {},
  },
];

export const CommissionsContext = createContext({
  commissions: [],
  commissionsFetch: (paginate) => {},
  commissionsLoading: true,
  commissionsTableHead: commissionsTableHead,
  commissionsFetchPaginate: (page, totalRows) => {},
  resetLoading: () => {},
  paginateOptions: {},
  optionsMenu: optionsMenu,
});

export const CommissionsContextProvider = ({ children }) => {
  const { loading, setLoading, resetLoading } = useBasicLoader(true);

  const {
    open: deleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose,
  } = useMUIModalState();

  const [data, setData] = useState([]);
  const [dataPaginate, setDataPaginate] = useState({});
  const [current, setCurrent] = useState(null);

  const dataFetch = async (dataParams) => {
    if (!dataParams) {
      return;
    }

    let { params, filters } = dataParams;

    if (!params) {
      return;
    }
    const { id, commissions_for } = params;

    setLoading(true);
    const [response, error] = await catchRequest(api.fetchCommissions, {
      commissions_for,
    });

    if (error) {
      console.log("error", error);
    }

    // data is in shape of
    // response.data.partners
    // data.partners contains the pagination
    // and partners.data will cotnain the array of partners
    // partners.data[index].partnerDetails will contain the partner details
    if (response && response.data) {
      setData(response.data.commission_users.data);
      let data = response.data.commission_users;
      delete data.data;

      setDataPaginate(data);
      setLoading(false);
    }
  };

  const dataFetchPaginate = async (dataParams) => {
    if (!dataParams) {
      return;
    }

    let { params, filters } = dataParams;

    if (!params) {
      return;
    }
    const { page, total_rows, commissions_for } = params;
    setLoading(true);
    const [response, error] = await catchRequest(api.fetchCommissions, {
      commissions_for,
      page: page + 1,
      total_rows: total_rows,
    });

    if (error) {
      console.log("error", error);
    }

    // data is in shape of
    // response.data.partners
    // data.partners contains the pagination
    // and partners.data will cotnain the array of partners
    // partners.data[index].partnerDetails will contain the partner details
    if (response && response.data) {
      setData(response.data.commission_users.data);
      let data = response.data.commission_users;
      delete data.data;

      setDataPaginate(data);
      setLoading(false);
    }
  };

  const handleCommissionPay = () => {};

  const optionsMenu = [
    {
      title: "Pay Commission",
      isButton: true,
      buttonAction: ({ data }) => {
        setCurrent(data);
        handleDeleteModalOpen();
      },
      path: () => {},
    },
  ];

  return (
    <CommissionsContext.Provider
      value={{
        commissions: data,
        commissionsFetch: dataFetch,
        commissionsLoading: loading,
        commissionsTableHead: commissionsTableHead,
        resetLoading,
        commissionsFetchPaginate: dataFetchPaginate,
        paginateOptions: dataPaginate,
        optionsMenu: optionsMenu,
      }}
    >
      <CommissionDeleteModal
        handleClose={handleDeleteModalClose}
        open={deleteModalOpen}
        data={data}
      />
      {children}
    </CommissionsContext.Provider>
  );
};

export const useCommissionsContext = () => {
  const { resetLoading } = useContext(CommissionsContext);

  useEffect(() => {
    return () => {
      resetLoading();
    };
  }, []);

  return useContext(CommissionsContext);
};
