import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Modal from "../../components/modal/modal";
import ViewProfile from "./viewProfile";
import TextField from "@mui/material/TextField";
import api from "../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import AddLinkIcon from "@mui/icons-material/AddLink";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@mui/material/MenuItem";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CustomizedMenus from "./menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import AlertDialogDelete from "../../components/modal/deletePrompt";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { useLocation } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  [`&.${tableRowClasses.root}`]: {
    height: "10px",
    padding: 0,
    margin: 0,
  },
}));

const Profiles = () => {
  // const { query } = Route();
  const navigation = useNavigate();
  // console.log("location", query);

  const tableRef = useRef(null);
  const [data, setData] = useState();
  const [filterData, setFilterData] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [search, setSearch] = useState();
  const [searchData, setSearchData] = useState();
  const [singleProfile, setSingleProfile] = useState();
  const [shared, setShared] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [response, setResponse] = useState();
  const [modalinfo, setModalInfo] = useState();
  const [loader1, setLoader1] = useState(false);

  useEffect(() => {
    getProfiles();
  }, []);

  const getProfiles = () => {
    api
      .getProfiles()
      .then((res) => {
        console.log("==>>", res.data);
        setData(res.data.profiles.map((e) => ({ ...e, isSelect: false })));
        setFilterData(
          res.data.profiles.map((e) => ({ ...e, isSelect: false }))
        );
        // setSearchData(res.data.profiles.filter((e) => e.isClient === 'Yes'))
        setShared(res.data.shares);
        let newArray =
          // setShared(
          res.data.profiles.filter((obj) => {
            const matching = res.data.shares.find(
              (obj2) => parseInt(obj2.share_with) === obj.id
            );
            console.log(matching, "matching");
            if (!matching) {
              return { ...obj, isShared: false };
            }
          });
        // )
        console.log(newArray, "newArray get profile");
        setSearchData(newArray.filter((e) => e.isClient === "Yes"));

        // setShared(newArray.filter((e) => e.isClient === 'Yes'))
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  // checking profile
  const handleCheck = (p) => {
    console.log(p, "for selected data");
    // let sahbd = shared.some((obj) => parseInt(obj.share_with) === p.id);
    // if (sahbd) {
    //   Swal.fire({
    //     title: `${p.name}'s profile  is Already Shared`,
    //     text: "Please Select another one",
    //     icon: "warning",
    //     // showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Cancel Selection",
    //   });
    //   return;
    // }
    setFilterData(
      filterData.map((e) =>
        e.id === p.id ? { ...e, isSelect: !e.isSelect } : e
      )
    );
    setData(
      data.map((e) => (e.id === p.id ? { ...e, isSelect: !e.isSelect } : e))
    );
    // console.log(sahbd,'sahbd');
  };

  useEffect(() => {
    setLoader1(true);
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        console.log("sea", search);
        searchProfile(search);
      } else {
        console.log(111, "not");
        let newArray =
          // setShared(
          data?.filter((obj) => {
            const matching = shared.find(
              (obj2) => parseInt(obj2.share_with) === obj.id
            );
            console.log(matching, "matching");
            if (!matching) {
              return { ...obj, isShared: false };
            }
          });
        // )
        console.log(newArray, "newArray on useEf");
        setSearchData(newArray?.filter((e) => e.isClient === "Yes"));
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const searchProfile = (s) => {
    console.log(s);
    setLoader1(true);
    api
      .searchProfile(s)
      .then((res) => {
        console.log("result==>", data && data);
        // setSearchData(res.data.search.map((e) => ({ ...e, selected: false })))
        // setSearchData(
        let notSelectedProfile;
        let newvar = res.data.search?.filter((obj) => {
          notSelectedProfile = data?.filter((profiles) => {
            if (profiles.isSelect === false) {
              return profiles.id !== obj.id;
            }
          });
          const matching = shared?.find(
            (obj2) => parseInt(obj2.share_with) === obj.id
          );
          if (!matching) {
            return { ...obj, isShared: false, selected: false };
          }
        });
        // );
        console.log(notSelectedProfile, "dasdsa");

        // console.log(getfinal, "getfinal");
        setSearchData(newvar);
        setLoader1(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader1(false);
      });
  };

  const handleSelectedFor = (data) => {
    console.log(data);
    setSearchData(
      searchData &&
        searchData.map((e) =>
          e.id === data.id ? { ...e, selected: !e.selected } : e
        )
    );
  };

  const notify = () =>
    toast("Copied", {
      autoClose: 500,
    });

  const msg = () => {
    // const copyText =(text)=>{
    async function copyText(text) {
      if ("clipboard" in navigator) {
        notify();
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand("copy", true, text);
      }
    }
    // }

    return (
      <>
        <div>
          <div className="text-center">
            <img src="/assets/success.gif" alt="" />
          </div>
          <div className="text-center">
            <h4>Profile sharing link created successfully </h4>
          </div>

          {response &&
            response.map((e) => (
              <div className="border">
                <div className="text-center mt-2">{e.user_name}</div>
                <div class="d-flex justify-content-center m-1 p-2 ">
                  <div class="border p-1 text-small btn-sm  rounded">
                    {window.location.origin.toString()}/SharedLink/{e.slag}
                  </div>
                  <button
                    class="btn btn-success btn-sm"
                    onClick={() =>
                      copyText(
                        `${window.location.origin.toString()}/SharedLink/${
                          e.slag
                        }`
                      )
                    }
                  >
                    {" "}
                    Copy{" "}
                  </button>
                </div>
                <div class="d-flex justify-content-center align-items-center m-1">
                  Passkey:{" "}
                  <div class="border p-1 text-small btn-sm  rounded px-3">
                    {e.passkey}
                  </div>
                  <button
                    class="btn btn-success btn-sm"
                    onClick={() => copyText(`${e.passkey}`)}
                  >
                    {" "}
                    Copy{" "}
                  </button>
                </div>
              </div>
            ))}
        </div>
      </>
    );
  };

  const createProfileLink = () => {
    setOpen(false);

    Swal.fire({
      title: "Are you sure?",
      text: "It will create a link and a Passkey!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmedShare();
        // Swal.fire({
        //     title: "Deleted!",
        //     text: "Your file has been deleted.",
        //     icon: "success"
        // });
      }
    });

    const confirmedShare = () => {
      var forWhom = searchData.filter((e) => e.selected === true);
      var profiles = data?.filter((e) => e.isSelect === true);
      console.log("forWhom", forWhom);
      console.log("profiles", profiles);
      api
        .createProfileLink(forWhom, profiles)
        .then((res) => {
          console.log("res", res.data.responseData);

          setResponse(res.data.responseData);
          setOpen2(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
  };

  const viewSingleProfile = (data) => {
    setSingleProfile([data]);
    setOpen3(true);
  };

  const handleActiveStatus = (data) => {
    console.log("worked", data.id);
    api
      .profileActiveStatus(data.id)
      .then((res) => {
        console.log("=>", res);
        getProfiles();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleDeleteProfile = (data) => {
    setModalInfo(data.id);
    setOpenDelete(true);
  };

  const handleDeletefinal = () => {
    // console.log("data bruda", data);
    console.log(modalinfo && modalinfo, "modalinfo");
    // return;
    api
      .deleteProfile(modalinfo && modalinfo)
      .then((res) => {
        console.log("deleted", res);
        setOpenDelete(false);
        if (open3) {
          setOpen3(false);
        }
        getProfiles();
        Swal.fire({
          title: "Delete successfully",
          icon: "success",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleMenuItemClick = (event, index) => {
    // console.log(event, 'get selected values');
    // let sahbd = shared.some(obj => parseInt(obj.share_with) === event.id)
    // if (sahbd) {
    //     Swal.fire({
    //         title: `${event.name}'s profile  is Already Shared`,
    //         text: "Please Select another one",
    //         icon: "warning",
    //         // showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Cancel Selection"
    //     })
    //     return;
    // }
    handleSelectedFor(event);
    setSelectedIndex(index);
  };

  // console.log(shared && shared,'Shared');
  const modalData = () => {
    return (
      <>
        <div className="p-3 border">
          <div>
            <div className="row">
              <div className="col-6">
                <div>
                  <div className="my-2">
                    <label htmlFor="">Search by Name or Phone Number </label>
                  </div>
                  <TextField
                    id="standard-basic"
                    label="For Whom?"
                    variant="outlined"
                    size="small"
                    className="form-control"
                    name="name"
                    onChange={(e) => setSearch(e.target.value)}
                    // onChange={(e) => handleSearch(e.target.value)}
                    value={search}
                  />

                  <div>
                    <Paper style={{ maxHeight: 400, overflow: "auto" }}>
                      {searchData?.map((option, index) => (
                        // <div className={shared?.some((e) => parseInt(e.share_with) === parseInt(profile.id)) && `bg-danger text-white`}>
                        // shared?.some((e) => parseInt(e.share_with) !== parseInt(option.id)) &&
                        <div key={index} className="">
                          {console.log(
                            searchData && searchData,
                            "optionsearchData"
                          )}
                          <MenuItem
                            key={option.id}
                            // disabled={index === 0}
                            // selected={index === selectedIndex}
                            onClick={(event) => {
                              handleMenuItemClick(option, index);
                            }}
                          >
                            {option.name} &nbsp;{" "}
                            {option.isClient === "Yes" && (
                              <div className="bg-success rounded text-white px-2 text-small">
                                Client
                              </div>
                            )}
                          </MenuItem>
                        </div>
                      ))}
                    </Paper>
                  </div>

                  <div>
                    {searchData && searchData.length === 0 && (
                      <div>Not Found</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-6 bg-light rounded">
                <div className="m-2 text-center">
                  Selected Person Whom you want to share
                </div>
                <div className="row">
                  {searchData &&
                    searchData
                      .filter((e) => e.selected === true)
                      .map(
                        (profile) =>
                          shared?.some(
                            (e) =>
                              parseInt(e.share_with) === parseInt(profile.id)
                          ) == false && (
                            <div className="col-4 mb-3">
                              <Paper>
                                <div
                                  className={
                                    shared?.some(
                                      (e) =>
                                        parseInt(e.share_with) ===
                                        parseInt(profile.id)
                                    ) && `bg-danger text-white`
                                  }
                                >
                                  <div style={{ position: "relative" }}>
                                    <div
                                      className=""
                                      style={{ position: "absolute", right: 7 }}
                                    >
                                      <Tooltip title="Delete">
                                        <IconButton color="error">
                                          <DeleteIcon
                                            onClick={() =>
                                              handleSelectedFor(profile)
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                    <CardMedia
                                      component="img"
                                      height="150"
                                      image={`${process.env.REACT_APP_BACKEND}img/profile/${profile.image}`}
                                      alt="Profile Photo"
                                    />
                                  </div>
                                  <div className="m-1">
                                    <div className="text-center text-small">
                                      <b>{profile.name}</b>
                                    </div>
                                    <div className="text-center text-small">
                                      Age: {profile.age}
                                    </div>
                                    <div className="text-center text-small pb-2">
                                      Phone: {profile.phone}
                                    </div>
                                  </div>
                                  <div className="text-center p-2">
                                    {shared?.some(
                                      (e) =>
                                        parseInt(e.share_with) ===
                                        parseInt(profile.id)
                                    ) && (
                                      <div className="text-small">
                                        {" "}
                                        <u> Link Already Created </u>
                                      </div>
                                    )}
                                    {/* <button className='btn btn-sm btn-danger' onClick={() => handleSelectedFor(profile)}>Cancel</button> */}
                                  </div>
                                </div>
                              </Paper>
                            </div>
                          )
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>View The Profiles that you selected</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div>
                <div className="p-3 border">
                  <div className="text-center">
                    <h3>Profiles that you want to share</h3>
                  </div>
                  <ViewProfile
                    data={data?.filter((e) => e.isSelect === true)}
                  />
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div>
          <div className="p-3 border">
            <div>
              <div className="text-center">
                <button className="btn btn-primary" onClick={createProfileLink}>
                  Create Profile link <AddLinkIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [fdata, setFdata] = useState({});

  useEffect(() => {
    handleFilter1();
  }, [fdata]);

  const handleFilter1 = (type, e) => {
    let filtered = data;
    if (fdata.name) {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(fdata.name.toLowerCase())
      );
    }
    if (fdata.current_city) {
      filtered = filtered.filter((item) =>
        item.current_city
          .toLowerCase()
          .includes(fdata.current_city.toLowerCase())
      );
    }
    if (fdata.mother_tongue) {
      filtered = filtered.filter((item) =>
        item.mother_tongue
          .toLowerCase()
          .includes(fdata.mother_tongue.toLowerCase())
      );
    }
    if (fdata.religion) {
      filtered = filtered.filter((item) => {
        item.religion.toLowerCase().includes(fdata.religion.toLowerCase());
      });
    }
    if (fdata.gender) {
      filtered = filtered.filter(
        (item) => item.gender.toLowerCase() === fdata.gender.toLowerCase()
      );
    }
    if (fdata.community) {
      filtered = filtered.filter((item) =>
        item.community.toLowerCase().includes(fdata.community.toLowerCase())
      );
    }
    if (fdata.phone) {
      filtered = filtered.filter((item) =>
        item.phone.toString().includes(fdata.phone.toString())
      );
    }
    if (fdata.age) {
      filtered = filtered.filter((item) =>
        item.age.toString().includes(fdata.age.toString())
      );
    }

    setFilterData(filtered);
  };

  const openCreateProfileModal = () => {
    // setSearchData(data.filter((e) => e.isClient === 'Yes'))
    console.log(shared && shared, data && data, "check odsaij");
    // return;
    // let newArray =
    const notSelectedProfile = data?.filter((obj) => obj.isSelect === false);
    setSearchData(
      notSelectedProfile?.filter((obj) => {
        const matching = shared?.find(
          (obj2) => parseInt(obj2.share_with) === obj.id
        );
        console.log(matching, "matching");
        if (!matching) {
          return { ...obj, isShared: false };
        }
      })
    );
    // console.log(newArray, 'newArray open modal');
    // setSearchData(newArray)
    setOpen(true);
  };

  return (
    <>
      <ToastContainer autoClose={500} />

      <div className="my-2">
        <button
          className="btn btn-success btn-sm"
          onClick={openCreateProfileModal}
          disabled={
            data?.filter((e) => e.isSelect === true).length ? false : true
          }
        >
          {" "}
          + Create Share Profile
        </button>{" "}
        &nbsp;
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => navigation("/admin/SharedProfiles")}
        >
          {" "}
          View Shared Account
        </button>
        <button
          className="btn btn-warning btn-sm float-end"
          onClick={() => navigation("/admin/CreateProfile")}
        >
          + Add Profile{" "}
        </button>
      </div>

      <Modal
        view={modalData()}
        size="lg"
        headerText="Share profile"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
      <Modal
        view={msg()}
        size="md"
        headerText="Jodi Perfect"
        open={open2}
        onOpen={() => setOpen2(true)}
        onClose={() => setOpen2(false)}
      />

      <Modal
        view={
          <ViewProfile
            data={singleProfile}
            handleRemove={handleDeleteProfile}
          />
        }
        size="lg"
        headerText="Jodi Perfect"
        open={open3}
        onOpen={() => setOpen3(true)}
        onClose={() => setOpen3(false)}
      />
      {/* <Modal
        view={
            AlertDialogDelete()
        }
        size="lg"
        headerText="Jodi Perfect"
        open={openDelete}
        onOpen={() => setOpenDelete(true)}
        onClose={() => setOpenDelete(false)}
      /> */}
      <AlertDialogDelete
        open={openDelete}
        size="lg"
        onOpen={() => setOpenDelete(true)}
        onClose={() => setOpenDelete(false)}
        handleDeletefinal={handleDeletefinal}
      />

      <div style={{ overflowX: "auto" }}>
        <TableContainer component={Paper} ref={tableRef}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                {/* <StyledTableCell>ID</StyledTableCell> */}
                <StyledTableCell align="center">
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={fdata.name}
                      placeholder="Name"
                      onChange={(e) =>
                        setFdata({ ...fdata, [e.target.name]: e.target.value })
                      }
                      id=""
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* Phone */}
                  <input
                    type="text"
                    name="phone"
                    value={fdata.phone}
                    placeholder="Phone"
                    onChange={(e) =>
                      setFdata({ ...fdata, [e.target.name]: e.target.value })
                    }
                    id=""
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* Gender  */}
                  {/* <UnfoldMoreIcon onClick={() => handleFilter('gender')} />  */}

                  {/* <input type="text" name="gender" value={fdata.gender} placeholder='Gender' onChange={(e)=>setFdata({ ...fdata, [e.target.name]: e.target.value })} id="" /> */}

                  <select
                    className="p-1"
                    name="gender"
                    id=""
                    value={fdata.gender}
                    onChange={(e) =>
                      setFdata({ ...fdata, [e.target.name]: e.target.value })
                    }
                  >
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* Age */}
                  <input
                    type="number"
                    name="age"
                    value={fdata.age}
                    placeholder="Age"
                    onChange={(e) =>
                      setFdata({ ...fdata, [e.target.name]: e.target.value })
                    }
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* City */}
                  <input
                    type="text"
                    name="current_city"
                    value={fdata.current_city}
                    placeholder="City"
                    onChange={(e) =>
                      setFdata({ ...fdata, [e.target.name]: e.target.value })
                    }
                    id=""
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* mother_tongue */}
                  <input
                    type="text"
                    name="mother_tongue"
                    value={fdata.mother_tongue}
                    placeholder="Mother tongue"
                    onChange={(e) =>
                      setFdata({ ...fdata, [e.target.name]: e.target.value })
                    }
                    id=""
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* religion */}
                  <input
                    type="text"
                    name="religion"
                    value={fdata.religion}
                    placeholder="Religion"
                    onChange={(e) =>
                      setFdata({ ...fdata, [e.target.name]: e.target.value })
                    }
                    id=""
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* Community */}
                  <input
                    type="text"
                    name="community"
                    value={fdata.community}
                    placeholder="Community"
                    onChange={(e) =>
                      setFdata({ ...fdata, [e.target.name]: e.target.value })
                    }
                    id=""
                  />
                </StyledTableCell>
                <StyledTableCell align="center">Marital Status</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData &&
                filterData.map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>
                      <Checkbox
                        checked={row.isSelect}
                        onClick={() => handleCheck(row)}
                      />
                    </StyledTableCell>
                    {/* <StyledTableCell component="th" scope="row" >
                                    {index + 1}
                                </StyledTableCell> */}
                    <StyledTableCell align="center">
                      {row.name}{" "}
                      {row.isClient === "Yes" && (
                        <span className="bg-success rounded text-white px-2 text-small">
                          Client
                        </span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      <a href={`tel:${row.phone}`}>{row.phone}</a>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.gender}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.age}
                      {/* (
                                    {new Date(row.dob).getDate()
                                        + ", " +
                                        new Date(row.dob).toLocaleString("default", { month: "long", })
                                        + "-" +
                                        new Date(row.dob).getFullYear()}
                                    ) */}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row.current_city}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.mother_tongue}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.religion}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.community}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.marital_status}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.isActive === 1 ? (
                        <div className="bg-success text-white">Active</div>
                      ) : (
                        <div className="bg-danger text-white">InActive</div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomizedMenus
                        handleActiveStatus={handleActiveStatus}
                        handleDeleteProfile={handleDeleteProfile}
                        // handleRemove={handleDeleteProfile}
                        data={row}
                        view={viewSingleProfile}
                        type="profile"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ViewProfile />
    </>
  );
};

export default Profiles;
