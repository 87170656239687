import React, { useEffect } from "react";
import { useLeadsContext } from "../../context/LeadsContext";
import CustomTable from "../../components/table/CustomTable";
import { useParams } from "react-router-dom";
import { useBasicLoader } from "../../hooks/useBasicLoader";

const CoordinatorManageLeadsPage = () => {
  const params = useParams();
  const coordinatorId = params.coordinatorId;

  const {
    leadsTableHead,
    leadsFetch,
    leadsLoading,
    leads,
    leadsPaginate,
    optionsMenu,
    leadsFetchPaginate,
  } = useLeadsContext();

  useEffect(() => {
    leadsFetch(coordinatorId, "coordinator");
  }, [coordinatorId]);

  const idLeadsFetch = (page, totalRows) => {
    leadsFetchPaginate(coordinatorId, "coordinator", page, totalRows);
  };

  return (
    <CustomTable
      title={`Leads by Coordinator ${coordinatorId}`}
      rows={leads}
      tableHead={leadsTableHead}
      loading={leadsLoading}
      paginateOptions={leadsPaginate}
      optionsMenu={optionsMenu}
      paginateFetch={idLeadsFetch}
    ></CustomTable>
  );
};

export default CoordinatorManageLeadsPage;
