import { createContext, useContext, useEffect, useState } from "react";
import { useBasicLoader } from "../hooks/useBasicLoader";
import { catchRequest } from "../hooks/useCatchRequest";
import api from "../API/api";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const coordinatorsTableHead = [
  {
    id: "uid",
    numeric: false,
    disablePadding: true,
    label: "Coordinator ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];

export const skeletonRows = [
  {
    id: 1,
    name: "ashdksadhw",
  },
  {
    id: 2,
    name: "ashdksadhw",
  },
  {
    id: 3,
    name: "ashdksadhw",
  },
  {
    id: 4,
    name: "ashdksadhw",
  },
  {
    id: 5,
    name: "ashdksadhw",
  },
];

export const CoordinatorsContext = createContext({
  coordinators: [],
  coordinatorsPaginate: {},
  coordinatorsFetch: (paginate) => {},
  coordinatorsLoading: true,
  coordinatorsTableHead: coordinatorsTableHead,
  coordinatorsFetchPaginate: (page, totalRows) => {},
  resetLoading: () => {},
  paginateOptions: {},
  optionsMenu: [],
});

export const CoordinatorsContextProvider = ({ children }) => {
  const { loading, setLoading, resetLoading } = useBasicLoader(true);

  const {
    loading: deleteLoading,
    setLoading: setDeleteLoading,
    resetLoading: resetDeleteLoading,
  } = useBasicLoader(false);

  const [coordinators, setCoordinators] = useState([]);
  const [coordinatorsPaginate, setCoordinatorsPaginate] = useState({});

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);

  const coordinatorsFetch = async () => {
    setLoading(true);
    const [response, error] = await catchRequest(api.fetchCoordinators);

    if (error) {
      console.log("error", error);
      setLoading(false);
    }

    // data is in shape of
    // response.data.coordinators
    // data.coordinators contains the pagination
    // and coordinators.data will cotnain the array of coordinators
    // coordinators.data[index].coordinator_details will contain the partner details
    if (response && response.data) {
      let newCoordinatorsArray = [];

      response.data.coordinators.data.forEach((coordinator) => {
        let coordinatorObject = {};
        coordinatorObject.id = coordinator.id;
        coordinatorObject.name = coordinator.name;
        coordinatorObject.email = coordinator.email;
        coordinatorObject.phone = coordinator.coordinator_details.phone;
        coordinatorObject.uid = coordinator.coordinator_details.uid;

        coordinatorObject.city = coordinator.coordinator_details.city;
        newCoordinatorsArray.push(coordinatorObject);
      });

      setCoordinators(newCoordinatorsArray);
      let data = response.data.coordinators;
      delete data.data;

      setCoordinatorsPaginate(data);
      setLoading(false);
    }
  };

  const coordinatorsFetchPaginate = async (page, totalRows) => {
    setLoading(true);
    const [response, error] = await catchRequest(api.fetchCoordinators, {
      page: page + 1,
      total_rows: totalRows,
    });

    if (error) {
      console.log("error", error);
    }

    // data is in shape of
    // response.data.coordinators
    // data.coordinators contains the pagination
    // and coordinators.data will cotnain the array of coordinators
    // coordinators.data[index].coordinator_details will contain the partner details
    if (response && response.data) {
      let newCoordinatorsArray = [];

      response.data.coordinators.data.forEach((coordinator) => {
        let coordinatorObject = {};
        coordinatorObject.id = coordinator.id;

        coordinatorObject.name = coordinator.name;
        coordinatorObject.phone = coordinator.coordinator_details.phone;
        coordinatorObject.uid = coordinator.coordinator_details.uid;

        coordinatorObject.city = coordinator.coordinator_details.city;
        newCoordinatorsArray.push(coordinatorObject);
      });

      setCoordinators(newCoordinatorsArray);
      let data = response.data.coordinators;
      delete data.data;

      setCoordinatorsPaginate(data);
      setLoading(false);
    }
  };

  const optionsMenu = [
    {
      title: "View",
      path: (coordinator) => {
        return `/admin/coordinators/manage/${coordinator.uid}`;
      },
    },
    {
      title: "View Partners",
      path: (coordinator) => {
        return `/admin/coordinators/manage/${coordinator.uid}/partners`;
      },
    },
    {
      title: "View Leads",
      isButton: false,
      path: (coordinator) => {
        return `/admin/coordinators/manage/${coordinator.uid}/leads`;
      },
    },
    // {
    //   title: "Delete",
    //   isButton: true,
    //   path: (leadId) => `/admin/coordinators/manage/${leadId}/delete`,
    //   buttonAction: async () => {
    //     handleDeleteModalOpen();
    //   },
    // },
  ];

  return (
    <CoordinatorsContext.Provider
      value={{
        coordinators,
        coordinatorsPaginate,
        coordinatorsFetch,
        coordinatorsLoading: loading,
        coordinatorsTableHead: coordinatorsTableHead,
        resetLoading,
        coordinatorsFetchPaginate,
        paginateOptions: coordinatorsPaginate,
        optionsMenu: optionsMenu,
      }}
    >
      <Modal
        open={deleteModalOpen}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      {children}
    </CoordinatorsContext.Provider>
  );
};

export const useCoordinatorContext = () => {
  const { resetLoading } = useContext(CoordinatorsContext);

  useEffect(() => {
    return () => {
      resetLoading();
    };
  }, []);

  return useContext(CoordinatorsContext);
};
