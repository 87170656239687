import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GridTextFieldGenerator from "../../components/textfield/GridTextFieldGenerator";

const LeadUpdateForm = ({
  form,
  formErrors,
  setFormValue,
  rawSetFormValue,
}) => {
  return (
    <>
      <>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Basic Info
          </AccordionSummary>
          <AccordionDetails>
            <GridTextFieldGenerator
              fields={[
                {
                  id: "name",
                  label: "Name",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.name,
                  onChange: (value) => {
                    setFormValue("name", value);
                  },
                },
                {
                  id: "phone",
                  label: "Phone",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.phone,
                  onChange: (value) => {
                    setFormValue("phone", value);
                  },
                },
                {
                  id: "email",
                  label: "Email",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.email,
                  onChange: (value) => {
                    setFormValue("email", value);
                  },
                },
                {
                  id: "city",
                  label: "City",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.city,
                  onChange: (value) => {
                    setFormValue("city", value);
                  },
                },
                {
                  id: "state",
                  label: "State",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.state,
                  onChange: (value) => {
                    setFormValue("state", value);
                  },
                },
                {
                  id: "address",
                  label: "Address",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.address,
                  onChange: (value) => {
                    setFormValue("address", value);
                  },
                },
              ]}
              form={form}
            ></GridTextFieldGenerator>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Additional Info
          </AccordionSummary>
          <AccordionDetails>
            <GridTextFieldGenerator
              fields={[
                {
                  id: "need_profile_for",
                  label: "Need Profile For",
                  field_type: "select",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  defaultValue: "",
                  value: form.need_profile_for,
                  select: true,
                  options: [
                    {
                      key: "Daughter",
                      value: "daughter",
                    },
                    {
                      key: "Son",
                      value: "son",
                    },
                    {
                      key: "Self",
                      value: "self",
                    },
                  ],
                  onChange: (value) => {
                    setFormValue("need_profile_for", value);
                  },
                },
                {
                  id: "age",
                  label: "Age",
                  field_type: "number",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.age,
                  onChange: (value) => {
                    setFormValue("age", value);
                  },
                },
                {
                  id: "search_status",
                  label: "Search Status",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.search_status,
                  onChange: (value) => {
                    setFormValue("search_status", value);
                  },
                },
                {
                  id: "partner_remarks",
                  label: "Partner Remarks",
                  field_type: "text",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.partner_remarks,
                  onChange: (value) => {
                    setFormValue("partner_remarks", value);
                  },
                },
              ]}
              form={form}
            ></GridTextFieldGenerator>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Lead Status
          </AccordionSummary>
          <AccordionDetails>
            <GridTextFieldGenerator
              fields={[
                {
                  id: "authenticate_lead",
                  label: "Authenticate Lead",
                  field_type: "select",
                  xs: 12,
                  md: 6,
                  lg: 6,
                  defaultValue: "",
                  value: form.authenticate_lead,
                  select: true,
                  options: [
                    {
                      key: "Verified By Call",
                      value: "verified_by_call",
                    },
                    {
                      key: "Pending Verification",
                      value: "pending_verification",
                    },
                    {
                      key: "No Response From Lead",
                      value: "no_response_from_lead",
                    },
                  ],
                  onChange: (value) => {
                    setFormValue("authenticate_lead", value);
                  },
                },
                {
                  id: "remarks_from_center",
                  label: "Remarks From Center",
                  field_type: "text",
                  xs: 12,
                  md: 6,
                  lg: 6,
                  value: form.remarks_from_center,
                  onChange: (value) => {
                    setFormValue("remarks_from_center", value);
                  },
                },
                {
                  id: "conversion_status",
                  label: "Conversion Status",
                  field_type: "select",
                  xs: 12,
                  md: 6,
                  lg: 6,
                  defaultValue: "",
                  value: form.conversion_status,
                  select: true,
                  options: [
                    {
                      key: "Pending",
                      value: "pending",
                    },
                    {
                      key: "In Process",
                      value: "in_process",
                    },
                    {
                      key: "Converted",
                      value: "converted",
                    },
                  ],
                  onChange: (value) => {
                    setFormValue("conversion_status", value);
                  },
                },
                {
                  id: "service_type",
                  label: "Service Type",
                  field_type: "select",
                  xs: 12,
                  md: 6,
                  lg: 6,
                  defaultValue: "",
                  value: form.service_type,
                  select: true,
                  options: [
                    {
                      key: "Classic",
                      value: "classic",
                    },
                    {
                      key: "Signature",
                      value: "signature",
                    },
                    {
                      key: "Royal",
                      value: "royal",
                    },
                  ],
                  onChange: (value) => {
                    setFormValue("service_type", value);
                  },
                },
                {
                  id: "service_amount",
                  label: "Service Amount",
                  field_type: "number",
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.service_amount,
                  onChange: (value) => {
                    // console.log("value, service amount", value, form);
                    setFormValue("service_amount", value);

                    if (Number(value) > 0) {
                      rawSetFormValue((prev) => ({
                        ...prev,
                        coordinator_commission: (Number(value) * 5) / 100,
                        partner_commission: (Number(value) * 10) / 100,
                      }));
                    }
                  },
                },
                {
                  id: "coordinator_commission",
                  label: "Coordinator Commission (5%)",
                  field_type: "number",
                  disabled: true,
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.coordinator_commission,
                  onChange: (value) => {
                    setFormValue("coordinator_commission", value);
                  },
                },
                {
                  id: "partner_commission",
                  label: "Partner Commission (10%)",
                  field_type: "number",
                  disabled: true,
                  xs: 12,
                  md: 4,
                  lg: 4,
                  value: form.partner_commission,
                  onChange: (value) => {
                    setFormValue("partner_commission", value);
                  },
                },
              ]}
              form={form}
            ></GridTextFieldGenerator>
          </AccordionDetails>
        </Accordion>
      </>
    </>
  );
};

export default LeadUpdateForm;
