import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../API/api";
import JoditEditor from "jodit-react";
import { Paper } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from 'dayjs';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


import { useLocation } from "react-router-dom";

const EditProfile = (props) => {

    const location = useLocation();
    console.log("==>", location.state.data);


    const [coverpreview, setCoverpreview] = useState();
    const [cover, setCover] = useState();


    const [description, setDescription] = useState("");



    const handleCoverImg = (e) => {
        setCover(e.target.files[0]);
        setCoverpreview(URL.createObjectURL(e.target.files[0]));
    };



    const [data, setData] = useState({});
    useEffect(()=>{
        setData(location.state.data)
    }, []);

    const handleChange = (e) => {
        console.log("name==>", e.target.name);
        console.log("==>", e.target.value);
        setData({
            ...data, [e.target.name]: e.target.value
        })
    }

    const handleSave = () => {
        console.log(data);

        // if(!cover){
        //     console.log("Image not found");

        //     Swal.fire({
        //         title: "Required",
        //         text: "Profile Image is required",
        //         icon: "question"
        //       });
        //     return
        // }

        if(!data.name){

            Swal.fire({
                title: "Required",
                text: "Name is required",
                icon: "question"
              });
            return
        }


        console.log("here");

        var formdata = new FormData();
        if(cover){
            formdata.append('image', cover);
        }

        formdata.append('id', data.name === undefined ? '': data.id);
        formdata.append('name', data.name === undefined ? '': data.name);
        formdata.append('phone', data.phone === undefined ? '': data.phone);
        formdata.append('email', data.email === undefined ? '': data.email);
        formdata.append('gender', data.gender === undefined ? '': data.gender);
        formdata.append('age', data.age === undefined ? '': data.age);
        formdata.append('mother_tongue', data.mother_tongue ===  undefined ? '' :  data.mother_tongue );
        formdata.append('dob', data.dob === undefined ? '': data.dob);
        formdata.append('place_of_birth', data.place_of_birth === undefined ? '': data.place_of_birth);
        formdata.append('time_of_birth', data.time_of_birth === undefined ? '': data.time_of_birth);
        formdata.append('current_address', data.current_address === undefined ? '': data.current_address);
        formdata.append('current_city', data.current_city === undefined ? '': data.current_city);
        formdata.append('marital_status', data.marital_status === undefined ? '': data.marital_status);
        formdata.append('community', data.community === undefined ? '': data.community);
        formdata.append('height', data.height === undefined ? '': data.height);
        formdata.append('weight', data.weight === undefined ? '': data.weight);
        formdata.append('annual_income', data.annual_income === undefined ? '': data.annual_income);
        formdata.append('complication', data.complication === undefined ? '': data.complication);
        formdata.append('body_type', data.body_type === undefined ? '': data.body_type);

        formdata.append('father_name', data.father_name === undefined ? '': data.father_name);
        formdata.append('f_occupations', data.f_occupations === undefined ? '': data.f_occupations);
        formdata.append('mother_name', data.mother_name === undefined ? '': data.mother_name);
        formdata.append('m_occupations', data.m_occupations === undefined ? '': data.m_occupations);
        formdata.append('no_of_brothers', data.no_of_brothers === undefined ? '': data.no_of_brothers);
        formdata.append('brothers_married', data.brothers_married === undefined ? '': data.brothers_married);
        formdata.append('no_of_sisters', data.no_of_sisters === undefined ? '': data.no_of_sisters);
        formdata.append('sisters_married', data.sisters_married === undefined ? '': data.sisters_married);

        formdata.append('religion', data.religion === undefined ? '': data.religion);
        formdata.append('caste', data.caste === undefined ? '': data.caste);
        formdata.append('sub_caste', data.sub_caste === undefined ? '': data.sub_caste);
        formdata.append('mangalik', data.mangalik === undefined ? '': data.mangalik);
        formdata.append('family_value', data.family_value === undefined ? '': data.family_value);

        formdata.append('diet', data.diet === undefined ? '': data.diet);
        formdata.append('smoke', data.smoke === undefined ? '': data.smoke);
        formdata.append('drink', data.drink === undefined ? '': data.drink);

        formdata.append('education', data.education === undefined ? '': data.education);
        formdata.append('profession', data.profession === undefined ? '': data.profession);
        formdata.append('isClient', data.isClient === undefined ? '': data.isClient);
        formdata.append('clientType', data.isClient === undefined ? '': data.clientType);

        console.log(process.env.REACT_APP_MAIN_API);
        var api = process.env.REACT_APP_MAIN_API+'updateProfile'

        axios.post(api, formdata).then((res)=>{
            console.log("res", res);
            Swal.fire({
                title: "Updated",
                text: "Successfully Updated",
                icon: "success"
              });
        })
        .catch((err)=>{
            console.log("error", err);
            Swal.fire({
                title: "Opps",
                text: "Something went wrong",
                icon: "error"
              });
        })
        
    }

    const basic_information = () => {
        return (
            <>

                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                id="standard-basic"
                                label="Name"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="name"
                                onChange={handleChange}
                                value={data.name}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                type="number"
                                id="standard-basic"
                                label="Phone No"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="phone"
                                onChange={handleChange}
                                value={data.phone}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Email Id"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="email"
                                onChange={handleChange}
                                value={data.email}
                            />
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-3">

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Gender</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.gender}
                                    // defaultValue={'Male'}
                                    label="Gender"
                                    name="gender"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Male'}>Male</MenuItem>
                                    <MenuItem value={'Female'}>Female</MenuItem>
                                    <MenuItem value={'Other'}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-3">
                            <TextField
                                type="number"
                                id="standard-basic"
                                label="Age"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="age"
                                onChange={handleChange}
                                value={data.age}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Mother Tongue"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="mother_tongue"
                                onChange={handleChange}
                                value={data.mother_tongue}
                            />

                        </div>

                        <div className="col-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Date of Birth"
                                    id="standard-basic"
                                    name="dob"
                                    value={dayjs(data.dob)}
                                    onChange={(e) => setData({ ...data, 'dob': dayjs(e.$d).format('YYYY-MM-DD') })}
                                    className="form-control"
                                    slotProps={{ textField: { size: "small" } }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-9">

                            <TextField
                                id="standard-basic"
                                label="Place of birth"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="place_of_birth"
                                onChange={handleChange}
                                value={data.place_of_birth}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Time of Birth"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="time_of_birth"
                                onChange={handleChange}
                                value={data.time_of_birth}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-9">

                            <TextField
                                id="standard-basic"
                                label="Current Address"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="current_address"
                                onChange={handleChange}
                                value={data.current_address}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Current City"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="current_city"
                                onChange={handleChange}
                                value={data.current_city}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            {/* <TextField
                                id="standard-basic"
                                label="Marital status"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="marital_status"
                                onChange={handleChange}
                                value={data.marital_status}
                            /> */}

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Marital status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.marital_status}
                                    // defaultValue={'Male'}
                                    label="Marital status"
                                    name="marital_status"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Single'}>Single</MenuItem>
                                    <MenuItem value={'Married'}>Married</MenuItem>
                                    <MenuItem value={'Divorced'}>Divorced</MenuItem>
                                    <MenuItem value={'Other'}>Other</MenuItem>
                                </Select>
                            </FormControl>




                        </div>

                        
                        {/* <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Community"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="community"
                                onChange={handleChange}
                                value={data.community}
                            />
                        </div> */}
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Height"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="height"
                                onChange={handleChange}
                                value={data.height}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Weight"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="weight"
                                onChange={handleChange}
                                value={data.weight}
                            />
                        </div>

                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Body type"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="body_type"
                                onChange={handleChange}
                                value={data.body_type}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Annul income"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="annual_income"
                                onChange={handleChange}
                                value={data.annual_income}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                id="standard-basic"
                                label="Complication"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="complication"
                                onChange={handleChange}
                                value={data.complication}
                            />
                        </div>
                        


                    </div>
                    <div className="row">

                        <input
                            accept="image/*"
                            //   className={classes.input}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={handleCoverImg}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="outlined" component="span" >
                               <AddAPhotoIcon /> &nbsp; Profile Picture
                            </Button>
                        </label>



                        {/* <div className="col-md-12 form-group my-3">
                            <input
                                type="file"
                                className="form-control"
                                name="cover"
                                id="imgInp"
                                onChange={handleCoverImg}
                            />
                        </div> */}


                        {coverpreview && (
                            <div className="col-md-12 form-group">
                                <label>Preview</label> <br />
                                <div
                                    className="border text-center p-3"
                                    style={{ height: "auto", alignItems: "center" }}
                                >
                                    <img
                                        id="blah"
                                        src={coverpreview}
                                        alt="Cover Image Preview"
                                        style={{ height: "180px", width: "fit-content" }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                </Box>

            </>
        )
    }

    const family_details = () => {
        return (
            <>
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                id="standard-basic"
                                label="Fathers name"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="father_name"
                                onChange={handleChange}
                                value={data.father_name}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                id="standard-basic"
                                label="Occupations"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="f_occupations"
                                onChange={handleChange}
                                value={data.f_occupations}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                id="standard-basic"
                                label="Mothers Name"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="mother_name"
                                onChange={handleChange}
                                value={data.mother_name}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                id="standard-basic"
                                label="Occupations"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="m_occupations"
                                onChange={handleChange}
                                value={data.m_occupations}
                            />
                        </div>
                    </div>

                    <div className="row">


                        <div className="col-3">
                            <TextField
                            type="number"
                                id="standard-basic"
                                label="No of Brothers"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="no_of_brothers"
                                onChange={handleChange}
                                value={data.no_of_brothers}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                            type="number"
                                id="standard-basic"
                                label="Brothers Married"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="brothers_married"
                                onChange={handleChange}
                                value={data.brothers_married}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                            type="number"
                                id="standard-basic"
                                label="No of Sisters"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="no_of_sisters"
                                onChange={handleChange}
                                value={data.no_of_sisters}
                            />

                        </div>
                        <div className="col-3">
                            <TextField
                            type="number"
                                id="standard-basic"
                                label="Sisters Married"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="sisters_married"
                                onChange={handleChange}
                                value={data.sisters_married}
                            />

                        </div>
                    </div>

                </Box>
            </>
        )
    }

    const religion = () => {
        return (
            <>
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div className="row">
                        <div className="col-4">
                            {/* <TextField
                                id="standard-basic"
                                label="Religion"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="religion"
                                onChange={handleChange}
                                value={data.religion}
                            /> */}

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Religion</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.religion}
                                    // defaultValue={'Male'}
                                    label="Religion"
                                    name="religion"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Hinduism'}>Hinduism</MenuItem>
                                    <MenuItem value={'Islam'}>Islam</MenuItem>
                                    <MenuItem value={'Christianity'}>Christianity</MenuItem>
                                    <MenuItem value={'Sikhism'}>Sikhism</MenuItem>
                                    <MenuItem value={'Buddhism'}>Buddhism</MenuItem>
                                    <MenuItem value={'Jainism'}>Jainism</MenuItem>
                                    <MenuItem value={'Other'}>Other</MenuItem>
                                </Select>
                            </FormControl>



                        </div>
                        <div className="col-4">
                            <TextField
                                id="standard-basic"
                                label="Community"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="community"
                                onChange={handleChange}
                                value={data.community}
                            />
                        </div>
                        <div className="col-4">
                            {/* <TextField
                                id="standard-basic"
                                label="Caste"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="caste"
                                onChange={handleChange}
                                value={data.caste}
                            /> */}

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Caste</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.caste}
                                    // defaultValue={'Male'}
                                    label="Caste"
                                    name="caste"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'General'}>General</MenuItem>
                                    <MenuItem value={'SC'}>SC</MenuItem>
                                    <MenuItem value={'ST-P'}>ST-P</MenuItem>
                                    <MenuItem value={'ST-H'}>ST-H</MenuItem>
                                    <MenuItem value={'Other'}>Other</MenuItem>
                                </Select>
                            </FormControl>



                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                id="standard-basic"
                                label="Sub caste"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="sub_caste"
                                onChange={handleChange}
                                value={data.sub_caste}
                            />
                        </div>
                        <div className="col-4">
                            {/* <TextField
                                id="standard-basic"
                                label="Mangalik"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="mangalik"
                                onChange={handleChange}
                                value={data.mangalik}
                            /> */}


                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Mangalik</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.mangalik}
                                    // defaultValue={'Male'}
                                    label="Mangalik"
                                    name="mangalik"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Yes'}>Yes</MenuItem>
                                    <MenuItem value={'No'}>No</MenuItem>
                                </Select>
                            </FormControl>





                        </div>
                        <div className="col-4">
                            <TextField
                                id="standard-basic"
                                label="Family Values"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="family_value"
                                onChange={handleChange}
                                value={data.family_value}
                            />
                        </div>
                    </div>

                </Box>

            </>
        )
    }

    const life_style = () => {
        return (
            <>
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div className="row">
                        <div className="col-4">
                            {/* <TextField
                                id="standard-basic"
                                label="Diet"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="diet"
                                onChange={handleChange}
                                value={data.diet}
                            /> */}


                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Diet</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.diet}
                                    // defaultValue={'Male'}
                                    label="Diet"
                                    name="diet"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Non-Veg'}>Non-Veg</MenuItem>
                                    <MenuItem value={'Veg'}>Veg</MenuItem>
                                    <MenuItem value={'Vegan'}>Vegan</MenuItem>
                                    <MenuItem value={'Others'}>Others</MenuItem>
                                </Select>
                            </FormControl>



                        </div>
                        <div className="col-4">
                            {/* <TextField
                                id="standard-basic"
                                label="Smoke"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="smoke"
                                onChange={handleChange}
                                value={data.smoke}
                            /> */}


                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Smoke</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.smoke}
                                    // defaultValue={'Male'}
                                    label="Smoke"
                                    name="smoke"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Yes'}>Yes</MenuItem>
                                    <MenuItem value={'No'}>No</MenuItem>
                                </Select>
                            </FormControl>


                        </div>
                        <div className="col-4">
                            {/* <TextField
                                id="standard-basic"
                                label="Drink"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="drink"
                                onChange={handleChange}
                                value={data.drink}
                            /> */}


                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Drink</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.drink}
                                    // defaultValue={'Male'}
                                    label="Drink"
                                    name="drink"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Yes'}>Yes</MenuItem>
                                    <MenuItem value={'No'}>No</MenuItem>
                                </Select>
                            </FormControl>



                        </div>
                    </div>



                </Box>
            </>
        )
    }

    const education = () => {
        return (
            <>
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                id="standard-basic"
                                label="Education"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="education"
                                onChange={handleChange}
                                value={data.education}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                id="standard-basic"
                                label="Profession"
                                variant="outlined"
                                size="small"
                                className="form-control"
                                name="profession"
                                onChange={handleChange}
                                value={data.profession}
                            />
                        </div>
                    </div>



                </Box>
            </>
        )
    }

    const business = () => {
        return (
            <>
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": { m: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div className="row">
                        <div className="col-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Is this a Client</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.isClient}
                                    label="Is this a Client"
                                    name="isClient"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Yes'}>Yes</MenuItem>
                                    <MenuItem value={'No'}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-6">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" size="small">Client Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.clientType}
                                    label="Client Type"
                                    name="clientType"
                                    size="small"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={''}>---</MenuItem>
                                    <MenuItem value={'Free'}>Free</MenuItem>
                                    <MenuItem value={'Paid'}>Paid</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                       
                    </div>



                </Box>
            </>
        )
    }

    return (
        <section>
            <div className="container">


                <div>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <Typography>BASIC INFROMATION </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {basic_information()}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <Typography>FAMILY INFROMATION </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {family_details()}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <Typography>RELIGION /SOCIAL BACGROUND </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {religion()}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <Typography>LIFE STYLE </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {life_style()}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <Typography>EDUCATION </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {education()}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <Typography>BUSINESS </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {business()}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <div className="mt-2">
                        <div className="text-center">
                            <button className="btn btn-success" onClick={handleSave}>Save Profile</button>
                        </div>
                    </div>

                </div>


            </div>
        </section>
    );
};

export default EditProfile;
