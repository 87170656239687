import React, { useEffect } from "react";
import { useLeadsContext } from "../../context/LeadsContext";
import CustomTable from "../../components/table/CustomTable";
import { useParams } from "react-router-dom";
import { useBasicLoader } from "../../hooks/useBasicLoader";

const PartnerManageLeadsPage = () => {
  const params = useParams();
  const partnerId = params.partnerId;

  const { loading: partnerLoading, setLoading: setPartnerLoading } =
    useBasicLoader(true);

  const {
    leadsTableHead,
    leadsFetch,
    leadsLoading,
    leads,
    leadsPaginate,
    optionsMenu,
    leadsFetchPaginate,
  } = useLeadsContext();

  useEffect(() => {
    leadsFetch(partnerId, "partner");
  }, [partnerId]);

  const partnerIdLeadsFetch = (page, totalRows) => {
    leadsFetchPaginate(partnerId, "partner", page, totalRows);
  };

  return (
    <CustomTable
      title={`Leads by Partner ${partnerId}`}
      rows={leads}
      tableHead={leadsTableHead}
      loading={leadsLoading}
      paginateOptions={leadsPaginate}
      optionsMenu={optionsMenu}
      paginateFetch={partnerIdLeadsFetch}
    ></CustomTable>
  );
};

export default PartnerManageLeadsPage;
