import React from 'react'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Paper } from '@mui/material';
import CustomizedMenus from './menu';


const ExpandMore = styled((props) => {

    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function ViewProfile(props) {
    // console.log("aa", props);

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleLikeProfile = (id) => {
        // console.log(id);
        if (props.handleLikeProfile) {
            props.handleLikeProfile(id)
        }
    }

    return (
        <div className='container'>
            <div className="row justify-content-center">

                {props.data?.map((e) => (
                    <div className="col-md-3 mb-4">
                        <Paper elevation={5} sx={{ maxWidth: 345 }} >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="">
                                        {e.name.charAt(0)}
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        {/* <MoreVertIcon onClick={()=>props.handleRemove(e)}/> */}
                                        <CustomizedMenus type="mainProfile" handleRemove={props.handleRemove} data={props.data[0]}/>
                                    </IconButton>
                                }
                                title={e.name}
                                subheader={
                                    new Date(e.dob).toLocaleString("default", { month: "long", })

                                    + " " + new Date(e.dob).getDate()
                                    + ", " +
                                    new Date(e.dob).getFullYear()}
                            />
                            <CardMedia
                                component="img"
                                height="294"
                                image={`${process.env.REACT_APP_BACKEND}img/profile/${e.image}`}
                                alt="Profile Photo"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    <div>
                                        Current City : <b> {e.current_city} </b>
                                    </div>
                                    {e.community &&
                                        <div>
                                            Community : <b>{e.community}</b>
                                        </div>
                                    }
                                    {e.annual_income &&
                                        <div>
                                            Annual Income : <b> {e.annual_income} </b>
                                        </div>
                                    }
                                    <div className='d-flex gap-3'>
                                        {e.height &&
                                            <div>
                                                Height : <b> {e.height} </b>
                                            </div>
                                        }
                                        {e.weight &&
                                            <div>
                                                Weight : <b> {e.weight} </b>
                                            </div>
                                        }
                                    </div>
                                    {e.mother_tongue &&
                                        <div>
                                            Mother Tongue : <b>{e.mother_tongue}</b>
                                        </div>
                                    }

                                    {e.religion &&
                                        <div>
                                            Religion : <b> {e.religion} </b>
                                        </div>
                                    }




                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                {e.isLiked && e.isLiked === 1 ?
                                    <IconButton aria-label="add to favorites" onClick={() => handleLikeProfile(e.s_id)}>
                                        <FavoriteIcon color='error' />
                                    </IconButton>
                                    :
                                    <IconButton aria-label="add to favorites" onClick={() => handleLikeProfile(e.s_id)}>
                                        <FavoriteIcon />
                                    </IconButton>
                                }







                                {/* <IconButton aria-label="share">
                                    <ShareIcon />
                                </IconButton> */}
                                <ExpandMore
                                    expand={expanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography paragraph><b> More About {e.name} : </b></Typography>
                                    <Typography paragraph>

                                        {e.education &&
                                            <div>
                                                Education : <b>{e.education}</b>
                                            </div>
                                        }

                                        {e.place_of_birth &&
                                            <div>
                                                Place of birth : <b>{e.place_of_birth}</b>
                                            </div>
                                        }
                                        {e.marital_status &&
                                            <div>
                                                Marital Status : <b>{e.marital_status}</b>
                                            </div>
                                        }
                                        {/* <div>
                                            Community : <b>{e.community}</b>
                                        </div> */}
                                    </Typography>
                                    <Typography paragraph>
                                        <div>
                                            Phone : <b>9101XXXXXX</b>
                                        </div>
                                    </Typography>
                                    <Typography paragraph>
                                        <div>
                                            <div className='mt-3'>
                                                <Stack direction="row" spacing={1}>
                                                    {e.diet &&
                                                        <Chip label={e.diet} size='small' />
                                                    }
                                                    {e.smoke === 'Yes' ? <Chip icon={<DoneIcon />} label="Smoke" color="success" variant='outlined' size='small' />
                                                        :
                                                        <Chip icon={<CloseIcon />} label="Smoke" color="error" variant='outlined' size='small' />
                                                    }

                                                    {e.drink === 'Yes' ? <Chip icon={<DoneIcon />} label="Drink" color="success" variant='outlined' size='small' />
                                                        :
                                                        <Chip icon={<CloseIcon />} label="Drink" color="error" variant='outlined' size='small' />
                                                    }

                                                </Stack>
                                            </div>
                                        </div>
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Paper>
                    </div>
                ))}


            </div>
        </div>
    )
}
