'use client'
import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 footer-col">
                            <h4>Jodi Perfect</h4>
                            <p className='text-white px-2 text-justify'>
                                In a world where faces are many, finding your human can be hard. We can help with that. At Jodi-Perfect, we assess your preferences and scan through our deep network of ‘good rishtas’ to find you your soulmate of a lifetime. Interested to give it shot? Talk to us!


                                {/* <li><a href="#">about us</a></li>
                                <li><a href="#">our services</a></li>
                                <li><a href="#">privacy policy</a></li>
                                <li><a href="#">affiliate program</a></li> */}
                            </p>
                        </div>
                        {/* <div className="col-md-4 footer-col">
                            <h4>get help</h4>
                            <ul>
                                <li><a href="#">About us</a></li>
                                <li><a href="#">Find Match</a></li>
                                <li><a href="#">privacy policy</a></li>
                                <li><a href="#">Call Us</a></li>
                            </ul>
                        </div> */}
                        <div className="col-md-2">

                        </div>

                        <div className="col-md-4 footer-col">
                            <h4>follow us</h4>
                            <div className="social-links">
                                <a href="https://www.facebook.com/jodiperfect.co" target='_blank'><i> <FacebookOutlinedIcon /> </i></a>
                                <a href="https://www.instagram.com/jodiperfect.co/" target='_blank'><i><InstagramIcon /></i></a>
                                <a href="https://twitter.com/home" target='_blank'><i><TwitterIcon /></i></a>
                                <a href="https://www.linkedin.com/company/100057642/admin/feed/posts/?feedType=following" target='_blank'><i><LinkedInIcon /></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>




            <div className="p-2 bg-danger">
                <div className="text-center">
                    <strong>Developed By:</strong> <a href="http://zaktidigital.com/" className='text-white'>Zakti Digital Pvt Ltd.</a>
                </div>
            </div>
        </>
    )
}

export default Footer;