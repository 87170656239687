import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../API/api";
import JoditEditor from "jodit-react";
import { Paper } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const CreateBlogs = (props) => {
  const [coverpreview, setCoverpreview] = useState();
  const [cover, setCover] = useState();

  // for all department getting from api
  const [department, setDepartment] = useState([]);

  const [value, setValue] = useState();
  const [doctorName, setDoctorName] = useState({
    id: "",
    name: "",
    specialization: "",
    department: "",
    qualification: "",
    achievements: "",
    image: "",
  });

  const [data, setData] = useState({})

  const [description, setDescription] = useState("");




  const handleInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleDate=(date)=>{
    // console.log(dayjs(date).format("YYYY-MM-DD HH:m:s"));
    let dateFormate  = dayjs(date).format("YYYY-MM-DD")
    setData({
      ...data, 'date': dateFormate
    })
  }

  const handleCoverImg = (e) => {
    setCover(e.target.files[0]);
    setCoverpreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = () => {
    console.log("=>>", data);
    console.log("=>>", description);
    // return;
    const postData = new FormData();
    // postData.append("id", doctorName.id);
    postData.append("title", data.title);
    postData.append("author_name", data.author_name);
    postData.append("tags", data.tags);
    postData.append("date", data.date);

    postData.append("description", data.description);
    postData.append("meta_title", data.meta_title);
    postData.append("meta_desc", data.meta_desc);

    postData.append("description", description);
    postData.append("image", cover);

    axios
      .post(`${process.env.REACT_APP_MAIN_API}postBlog`, postData)
      .then((res) => {
        console.log(res);
        // props.handleClose();

        Swal.fire({
          title: "Success!",
          text: "Added Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleDetails = (e) => {
    setDescription(e);
  };

  // jodit editor
  const editor = useRef(null);

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: 420,
    triggerChangeEvent: true,
    readonly: false,
  };

  const Jodit = () => {
    return useMemo(
      () => (
        <JoditEditor
          ref={editor}
          config={config}
          value={description}
          onChange={handleDetails}
        />
      ),
      [department]
    );
  };

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <Paper elevation={5}>
              <div className="p-5 border">
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="standard-basic"
                    label="Blog Title"
                    variant="outlined"
                    size="small"
                    className="form-control"
                    name="title"
                    onChange={handleInput}
                    value={data.title}
                  />

                  <TextField
                    id="standard-basic"
                    label="Author Name"
                    variant="outlined"
                    size="small"
                    className="form-control"
                    name="author_name"
                    onChange={handleInput}
                    value={data.author_name}
                  />

                  <TextField
                    id="standard-basic"
                    label="Tags (Separate by comma)"
                    variant="outlined"
                    size="small"
                    className="form-control"
                    name="tags"
                    onChange={handleInput}
                    value={data.tags}
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Blog Date"
                        id="standard-basic"
                        className="form-control"
                        name="date"
                        date= {data.date}
                        onChange={(e)=> handleDate(e) }
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <label htmlFor="">Description</label>
                  {Jodit()}

<br/>

                  <TextField
                    id="standard-basic"
                    label="Meta Title"
                    variant="outlined"
                    size="small"
                    className="form-control"
                    name="meta_title"
                    onChange={handleInput}
                    value={data.meta_title}
                  />
                  <TextField
                    id="standard-basic"
                    label="Meta Description"
                    variant="outlined"
                    size="small"
                    className="form-control"
                    name="meta_desc"
                    onChange={handleInput}
                    value={data.meta_desc}
                  />

                  <div className="col-md-12 form-group my-3">
                    <input
                      type="file"
                      className="form-control"
                      name="cover"
                      id="imgInp"
                      onChange={handleCoverImg}
                    />
                  </div>
                  {coverpreview && (
                    <div className="col-md-12 form-group">
                      <label>Preview</label> <br />
                      <div
                        className="border text-center p-3"
                        style={{ height: "auto", alignItems: "center" }}
                      >
                        <img
                          id="blah"
                          src={coverpreview}
                          alt="Cover Image Preview"
                          style={{ height: "180px", width: "fit-content" }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="text-center my-4">
                    <Button variant="contained" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </div>
                </Box>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateBlogs;
