import React, { useEffect } from "react";
import { useCoordinatorContext } from "../../context/CoordinatorContext";
import CustomTable from "../../components/table/CustomTable";
import { useCommissionsContext } from "../../context/CommissionContext";

const CoordinatorsCommissionsPage = () => {
  const {
    commissions,
    commissionsFetch,
    commissionsFetchPaginate,
    commissionsLoading,
    optionsMenu,
    commissionsTableHead,
    paginateOptions,
    resetLoading,
  } = useCommissionsContext();

  useEffect(() => {
    commissionsFetch({
      params: {
        commissions_for: "coordinator",
      },
    });
  }, []);

  const paginateFetch = (page, totalRows) => {
    commissionsFetchPaginate({
      params: {
        page: page,
        total_rows: totalRows,
        commissions_for: "coordinator",
      },
    });
  };

  return (
    <CustomTable
      loading={commissionsLoading}
      optionsMenu={optionsMenu}
      paginateFetch={paginateFetch}
      paginateOptions={paginateOptions}
      rows={commissions}
      tableHead={commissionsTableHead}
      title="Commissions for coordinators"
    ></CustomTable>
  );
};

export default CoordinatorsCommissionsPage;
