import { Box } from "@mui/material";

/**
 * Renders a container with a black border and fills the entire width and height of its parent.
 * This is a flex container with flex direction column
 *
 * @param {Object} props - The props object containing the children to be rendered inside the container.
 * @param {ReactNode} props.children - The children to be rendered inside the container.
 * @param {"row" | "column"} [props.direction = "column"] - The direction of the container. default is column
 * @param {boolean} [props.isScrollable = false] - Whether the container should be scrollable.
 * @return {JSX.Element} A Box component with the specified styles and containing the children.
 */
export default function ScrollableContainer({
  children,
  direction = "column",
  ...props
}) {
  return (
    <Box
      component={"main"}
      sx={{
        display: "flex",
        flex: "1",
        flexDirection: direction,
        width: "100%",
        boxSizing: "border-box",

        overflow: "auto",
        flexBasis: 0,
        minHeight: "5px",

        ...props,
      }}
    >
      {children}
    </Box>
  );
}
