import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useBasicLoader } from "../hooks/useBasicLoader";
import { catchRequest } from "../hooks/useCatchRequest";
import api from "../API/api";
import { useUserContext } from "./UserContext";

/**
 * @type {import('./context__jsdoc.types').LeadsTableHeadArrayType}
 */
export const leadsTableHead = [
  {
    id: "uid",
    numeric: false,
    disablePadding: false,
    label: "Lead ID",
    minWidth: 150,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    // editable: true,
    minWidth: 150,
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
    // editable: true,
    minWidth: 200,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "need_profile_for",
    numeric: false,
    disablePadding: false,
    label: "Need Profiles For",
  },
  {
    id: "age",
    numeric: false,
    disablePadding: false,
    label: "Age",
  },
  {
    id: "search_status",
    numeric: false,
    disablePadding: false,
    label: "Search Status",
  },
  {
    id: "partner_remarks",
    numeric: false,
    disablePadding: false,
    label: "Partner Remarks",
  },
  {
    id: "authenticate_lead",
    numeric: false,
    disablePadding: false,
    label: "Authenticate Lead",
  },
  {
    id: "remarks_from_center",
    numeric: false,
    disablePadding: false,
    label: "Remarks From Center",
  },
  {
    id: "conversion_status",
    numeric: false,
    disablePadding: false,
    label: "Conversion Status",
  },
  {
    id: "service_amount",
    numeric: true,
    disablePadding: false,
    label: "Service Amount",
  },
  {
    id: "coordinator_commission",
    numeric: false,
    disablePadding: false,
    label: "Coordinator Commission",
  },
  {
    id: "partner_commission",
    numeric: false,
    disablePadding: false,
    label: "Partner Commission",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export const skeletonRows = [
  {
    id: 1,
    name: "ashdksadhw",
  },
  {
    id: 2,
    name: "ashdksadhw",
  },
  {
    id: 3,
    name: "ashdksadhw",
  },
  {
    id: 4,
    name: "ashdksadhw",
  },
  {
    id: 5,
    name: "ashdksadhw",
  },
];

export const optionsMenu = [
  {
    title: "View",
    path: (lead) => {
      return `/admin/leads/manage/${lead.uid}`;
    },
  },
];

export const LeadsContext = createContext({
  leads: [],
  leadsPaginate: {},
  leadsFetch: (paginate) => {},
  leadsLoading: true,
  leadsTableHead: leadsTableHead,
  leadsFetchPaginate: (page, totalRows) => {},
  resetLoading: () => {},
  paginateOptions: {},
  optionsMenu: optionsMenu,
});

export const LeadsContextProvider = ({ children }) => {
  const { user } = useUserContext();

  const { loading, setLoading, resetLoading } = useBasicLoader(true);

  const [data, setData] = useState([]);
  const [dataPaginate, setDataPaginate] = useState({});

  const dataFetch = async (id, role) => {
    setLoading(true);

    let requestParams = {
      creator_id: id,
      creator_role: role,
    };

    if (user && user.role === "coordinator") {
      requestParams.creator_id = user.uid;
    }

    const [response, error] = await catchRequest(api.fetchLeads, requestParams);

    if (error) {
      console.log("error", error);
    }

    // data is in shape of
    // response.data.partners
    // data.partners contains the pagination
    // and partners.data will cotnain the array of partners
    // partners.data[index].partnerDetails will contain the partner details
    if (response && response.data) {
      setData(response.data.leadsObject.data);
      let data = response.data.leadsObject;
      delete data.data;

      setDataPaginate(data);
      setLoading(false);
    }
  };

  const dataFetchPaginate = async (id, role, page, totalRows) => {
    setLoading(true);

    let requestParams = {
      creator_id: id,
      creator_role: role,
      page: page + 1,
      total_rows: totalRows,
    };

    if (user && user.role === "coordinator") {
      requestParams.creator_id = user.uid;
    }

    const [response, error] = await catchRequest(api.fetchLeads, requestParams);

    if (error) {
      console.log("error", error);
    }

    // data is in shape of
    // response.data.partners
    // data.partners contains the pagination
    // and partners.data will cotnain the array of partners
    // partners.data[index].partnerDetails will contain the partner details
    if (response && response.data) {
      let newDataArray = [];

      setData(response.data.leadsObject.data);
      let data = response.data.leadsObject;
      delete data.data;

      setDataPaginate(data);
      setLoading(false);
    }
  };

  const isAdmin = useMemo(() => {
    return user && user.role === "admin";
  }, [user]);

  /**
   * @type {import('./context__jsdoc.types').LeadsTableHeadArrayType}
   */
  const leadsTableHead = useMemo(
    () => [
      {
        id: "uid",
        numeric: false,
        disablePadding: false,
        label: "Lead ID",
        minWidth: 150,
      },
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
        // editable: true,
        minWidth: 150,
      },
      {
        id: "phone",
        numeric: true,
        disablePadding: false,
        label: "Phone Number",
        // editable: true,
        minWidth: 200,
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
      },
      {
        id: "city",
        numeric: false,
        disablePadding: false,
        label: "City",
      },
      {
        id: "state",
        numeric: false,
        disablePadding: false,
        label: "State",
      },
      {
        id: "address",
        numeric: false,
        disablePadding: false,
        label: "Address",
      },
      {
        id: "need_profile_for",
        numeric: false,
        disablePadding: false,
        label: "Need Profiles For",
      },
      {
        id: "age",
        numeric: false,
        disablePadding: false,
        label: "Age",
      },
      {
        id: "search_status",
        numeric: false,
        disablePadding: false,
        label: "Search Status",
      },
      {
        id: "partner_remarks",
        numeric: false,
        disablePadding: false,
        label: "Partner Remarks",
      },
      {
        id: "authenticate_lead",
        numeric: false,
        disablePadding: false,
        label: "Authenticate Lead",
      },
      {
        id: "remarks_from_center",
        numeric: false,
        disablePadding: false,
        label: "Remarks From Center",
      },
      {
        id: "conversion_status",
        numeric: false,
        disablePadding: false,
        label: "Conversion Status",
      },
      {
        id: "service_amount",
        numeric: true,
        disablePadding: false,
        label: "Service Amount",
      },
      {
        id: "coordinator_commission",
        numeric: false,
        disablePadding: false,
        label: "Coordinator Commission",
      },
      {
        id: "partner_commission",
        numeric: false,
        disablePadding: false,
        label: "Partner Commission",
      },
      isAdmin && {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
      },
    ],
    [user]
  );

  return (
    <LeadsContext.Provider
      value={{
        leads: data,
        leadsPaginate: dataPaginate,
        leadsFetch: dataFetch,
        leadsLoading: loading,
        leadsTableHead: leadsTableHead,
        resetLoading,
        leadsFetchPaginate: dataFetchPaginate,
        paginateOptions: dataPaginate,
        optionsMenu: optionsMenu,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export const useLeadsContext = () => {
  const { resetLoading } = useContext(LeadsContext);

  useEffect(() => {
    return () => {
      resetLoading();
    };
  }, []);

  return useContext(LeadsContext);
};
