import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  LeaderboardSharp as LeaderboardSharpIcon,
  BookOnline as BookOnlineIcon,
  Apartment as ApartmentIcon,
  Contacts as ContactsIcon,
  AddTask as AddTaskIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Handshake as HandshakeIcon,
  ManageAccounts as ManageAccountsIcon,
  People as PeopleIcon,
  Grading as GradingIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";

/**
 * AdminDrawer component for the admin dashboard sidebar.
 *
 * @param {Object} props
 * @param {boolean} props.open2 - State to control the open/close state of the drawer.
 * @param {function} props.setOpen2 - Function to set the open2 state.
 * @param {boolean} props.open - State to control the open/close state of the Profile submenu.
 * @param {function} props.handleClick - Function to handle the click event for the Profile submenu.
 * @param {boolean} props.openPartners - State to control the open/close state of the Partners submenu.
 * @param {function} props.handleClickPartners - Function to handle the click event for the Partners submenu.
 * @param {boolean} props.openCoordinators - State to control the open/close state of the Coordinators submenu.
 * @param {function} props.handleClickCoordinators - Function to handle the click event for the Coordinators submenu.
 * @param {boolean} props.openLeads - State to control the open/close state of the Leads submenu.
 * @param {function} props.handleClickLeads - Function to handle the click event for the Leads submenu.
 * @param {function} props.logout - Function to handle the logout action.
 */
const CoordinatorDrawer = ({
  open2,
  setOpen2,
  open,
  handleClick,
  openPartners,
  handleClickPartners,
  openCoordinators,
  handleClickCoordinators,
  openLeads,
  handleClickLeads,
  logout,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Toolbar>
        <div>
          {/* <img src="/logo.png" className='img-fluid' /> */}
          {/* ****************************************** */}
          {!open2 ? (
            <MenuIcon onClick={() => setOpen2(true)} />
          ) : (
            <h2>
              Jodi Perfect <MenuIcon onClick={() => setOpen2(false)} />
            </h2>
          )}
          {/* ******************************************           */}
        </div>
      </Toolbar>
      <Divider />
      <List>
        <ListItem
          disablePadding
          onClick={() => navigate("/coordinator/dashboard")}
        >
          <ListItemButton>
            <ListItemIcon>
              <LeaderboardSharpIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItemButton onClick={handleClickPartners}>
          <ListItemIcon>
            <HandshakeIcon />
          </ListItemIcon>
          <ListItemText primary="Partner" />
          {openPartners ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openPartners} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/coordinator/partners/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Partners" />
            </ListItemButton>

            {/* <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/coordinator/partners/create")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Create Partners" />
            </ListItemButton> */}

            {/* <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/coordinator/partners/commissions")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Commissions" />
            </ListItemButton> */}
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickLeads}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Leads" />
          {openLeads ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openLeads} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/coordinator/leads/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Leads" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* There was commented code here section */}

        <ListItemButton disablePadding onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
      </List>
      <Divider />
    </div>
  );
};

export default CoordinatorDrawer;
