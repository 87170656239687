import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material'

import ViewProfile from './viewProfile';
import api from '../../API/api'
import Footer from '../../components/common/footer';
import Modal from '../../components/modal/modal';
import TextField from '@mui/material/TextField';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function SharedLink() {

    const params = useParams();
    console.log("=", params.id);
    const [profilesData, setProfilesData] = useState();
    const [userdata, setUserdata] = useState();

    const [value, setValue] = useState();

    const [open, setOpen] = useState(true)

    const sharedProfiles = () => {
        if (params.id) {
            // if (!open) {
            api.sharedProfiles(params.id).then((res) => {
                console.log("res", res);
                setProfilesData(res.data.profiles);
                setUserdata(res.data.user)
            })
                .catch((err) => {
                    console.log("err", err);
                })
            // }
        }
    }

    useEffect(() => {
        sharedProfiles();
    }, [params.id]);


    const varifyPasskey = () => {

        if (value === userdata?.password) {
            setOpen(false)
        }else{
            notify();
        }
    }

    const notify = () => toast("Wrong Passkey or Link Expired",{
        autoClose:500
    });

    const handleLikeProfile = (id)=>{
        console.log("liked", id);
        api.likeProfile(id).then((res)=>{
            console.log("liked handled", res);
            sharedProfiles()
        })
        .catch((err)=>{
            console.log("err", err);
        })
    }

    const modalData = () => {
        return (
            <>
                <div>
                    <div className='text-center'>
                        <img src="https://jodiperfect.co/img/logo.png" width={150} alt="" />
                    </div>
                    <div className="text-center">
                        <div>
                            <b>Enter your PassKey</b>
                        </div>
                        <div className='d-flex justify-content-center align-items-center gap-2 bg-light'>

                            <div>
                                <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="name"
                                    name="email"
                                    label="Passkey"
                                    type="email"
                                    fullWidth
                                    variant="filled"
                                    size='small'
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                            </div>
                            <div>
                                <button className='btn btn-success' onClick={varifyPasskey}>Verify</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    return (
        <>

            <ToastContainer autoClose={500} />
            <div className="bg-cus  py-5 ">
                <div className='container'>

                    <div className="hero">
                        <div className="text-center">
                            <div className="row align-items-center">
                                <div className="col" data-aos="fade-in">

                                </div>
                                <div className="col-md-8">
                                    <div className="sldier-creat ">
                                        <div className="custom" data-aos="zoom-in">
                                            <h1 className="font1 text-uppercase">
                                                Your soulmate asked us <br /> to look for you!
                                            </h1>
                                            <img src="https://jodiperfect.co/img/logo.png" width={150} alt="" />
                                        </div>

                                        <div>
                                            <div className="sub-head">
                                                Let’s get you two connected!
                                            </div>
                                        </div>

                                        <div className=" text-center">
                                            {/* <DialogForm modalClose={modalClose} btnClass='btn btn-outline-danger m-2' text={signUpBtn()} form={<Form1 modalControl={modalControl} />} /> */}
                                        </div>
                                    </div>

                                </div>
                                <div className="col">

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='p-3'>

                <div className='text-center m-3'>
                    <h3>Find Your Match</h3>
                </div>

                {open ?


                    <div style={{ height: '100vh', position: 'relative', background: 'black' }}>
                        <Modal view={modalData()} open={open} />
                    </div>
                    :

                    <ViewProfile data={profilesData} handleLikeProfile={handleLikeProfile}/>
                }
            </div>


            <Footer />
        </>
    )
}







