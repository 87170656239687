import React, { useEffect } from "react";
import { useLeadsContext } from "../../context/LeadsContext";
import CustomTable from "../../components/table/CustomTable";
import { useParams } from "react-router-dom";
import { useBasicLoader } from "../../hooks/useBasicLoader";
import AuthUser from "../../API/token";

const LeadsManagePage = () => {
  const params = useParams();
  const partnerId = params.partnerId;

  const { loading: partnerLoading, setLoading: setPartnerLoading } =
    useBasicLoader(true);

  const { user } = AuthUser();

  const {
    leadsTableHead,
    leadsFetch,
    leadsLoading,
    leads,
    leadsPaginate,
    optionsMenu,
    leadsFetchPaginate,
  } = useLeadsContext();

  useEffect(() => {
    leadsFetch(user.id, "admin");
  }, [partnerId]);

  const partnerIdLeadsFetch = (page, totalRows) => {
    leadsFetchPaginate(user.id, "admin", page, totalRows);
  };

  return (
    <CustomTable
      title={`All Leads`}
      rows={leads}
      tableHead={leadsTableHead}
      loading={leadsLoading}
      paginateOptions={leadsPaginate}
      optionsMenu={optionsMenu}
      paginateFetch={partnerIdLeadsFetch}
    ></CustomTable>
  );
};

export default LeadsManagePage;
