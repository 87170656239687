import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Appointmemnt from '../form/appointment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { CallUs } from '../panel/callus';


export default function Modal(props) {

    return (
        <React.Fragment>
            {/* <Button variant='contained' size='small' color='warning' className='blinking-button' onClick={props.onOpen}>
                <EventNoteIcon /> &nbsp; Appointment
            </Button> */}
            <Dialog
                fullWidth={true}
                maxWidth={props.size? props.size : 'md'}
                open={props.open}
                onClose={props.onClose}
            >
                <DialogTitle>{props.headerText}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                   {props.view}

                </DialogContent>
                <DialogActions>
                    {/* <CallUs /> */}
                    <Button onClick={props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}