import { useState } from "react";

/**
 * Returns an object with a `loading` state and a function to update it.
 *  @function
 * @param {boolean} [initialValue=false] - The initial value of the `loading` state.
 *
 * @return {import('./hooks_type').useBasicLoader}
 */
export const useBasicLoader = (initialValue = false) => {
  const [loading, setLoading] = useState(initialValue);
  const resetLoading = () => setLoading(initialValue);
  return {
    loading,
    setLoading,
    resetLoading,
  };
};
