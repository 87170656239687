import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useBasicLoader } from "../../hooks/useBasicLoader";
import { useParams } from "react-router-dom";
import api from "../../API/api";
import useCreateForm from "../../hooks/useFormState";
import {
  catchRequest,
  catchRequestWithParams,
} from "../../hooks/useCatchRequest";
import GridTextFieldGenerator from "../../components/textfield/GridTextFieldGenerator";
import ScrollableContainer from "../../components/containers/ScrollableContainer";
import toast from "react-hot-toast";

const CoordinatorManagePage = () => {
  const { loading, setLoading } = useBasicLoader(true);

  const { form, setFormValue, rawSetFormValue } = useCreateForm({
    name: "",
    email: "",
    phone: "",
    city: "",
  });

  const [coordinator, setCoordinator] = useState(null);

  const params = useParams();

  let coordinatorId = params.coordinatorId;
  const getCoordinator = async () => {
    setLoading(true);
    const [response, error] = await catchRequest(api.fetchCoordinator, {
      coordinator_id: coordinatorId,
    });

    if (response && response.data.success) {
      setCoordinator(response.data.coordinator);
      rawSetFormValue(response.data.coordinator);
      setLoading(false);
    }

    if (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCoordinator();
  }, [coordinatorId]);

  const handleUpdate = async () => {
    const [response, error] = await catchRequestWithParams(
      api.updateCoordinator,
      coordinatorId,
      {
        ...form,
      }
    );

    if (response && response.data.success) {
      toast.success(response.data.message);
    }

    if (error) {
      console.log("error ", error);
      toast.error(error.response.data.message.split(".")[0]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        // minWidth: "80vw",
        minHeight: "85vh",
        margin: 0,
        padding: 0,
        flex: 1,
      }}
    >
      <Paper elevation={5} sx={{ flex: 1, padding: "1rem" }}>
        <Stack
          direction={"row"}
          sx={{ justifyContent: "space-between" }}
          alignItems={"center"}
        >
          <Typography variant="h3" sx={{ paddingY: "0.5rem" }}>
            Manage Coordinator <span>{coordinator && coordinator.name}</span>
          </Typography>
          <Box>
            <Button variant="contained" onClick={handleUpdate}>
              <Typography variant="body1">Update</Typography>
            </Button>
          </Box>
        </Stack>

        <ScrollableContainer direction="column" padding="1rem">
          {loading && <Typography>Loading...</Typography>}
          {!loading && (
            <GridTextFieldGenerator
              fields={[
                {
                  id: "name",
                  label: "Name",
                  field_type: "text",
                  required: true,
                  defaultValue: coordinator && coordinator.name,
                  disabled: false,
                  lg: 4,
                  md: 4,
                  sm: 12,
                  xs: 12,
                  onChange: (e) => {
                    setFormValue("name", e);
                  },
                },
                {
                  id: "phone",
                  label: "Phone",
                  field_type: "number",
                  required: true,
                  defaultValue: coordinator && coordinator.phone,
                  disabled: false,
                  lg: 4,
                  md: 4,
                  sm: 12,
                  xs: 12,
                  onChange: (e) => {
                    setFormValue("phone", e);
                  },
                },
                {
                  id: "city",
                  label: "City",
                  field_type: "text",
                  required: true,
                  defaultValue: coordinator && coordinator.city,
                  disabled: false,
                  lg: 4,
                  md: 4,
                  sm: 12,
                  xs: 12,
                  onChange: (e) => {
                    setFormValue("city", e);
                  },
                },
                {
                  id: "email",
                  label: "Email",
                  field_type: "text",
                  required: true,
                  defaultValue: coordinator && coordinator.email,
                  disabled: false,
                  lg: 6,
                  md: 6,
                  sm: 12,
                  xs: 12,
                  onChange: (e) => {
                    setFormValue("email", e);
                  },
                },
                {
                  id: "password",
                  label: "Password",
                  field_type: "text",
                  required: true,
                  defaultValue: coordinator && coordinator.password,
                  disabled: false,
                  lg: 6,
                  md: 6,
                  sm: 12,
                  xs: 12,
                  onChange: (e) => {
                    setFormValue("password", e);
                  },
                },
              ]}
              form={form}
            ></GridTextFieldGenerator>
          )}
        </ScrollableContainer>
      </Paper>
    </Box>
  );
};

export default CoordinatorManagePage;
