import { useState } from "react";

export default function useFormErrors() {
  const [formErrors, setFormErrors] = useState({});

  const checkAndGenerateFormError = (formState, errorIgnoreObject) => {
    let localErrors = {};
    Object.keys(formState).forEach((key) => {
      if (formState[key] === "" && !errorIgnoreObject[key]) {
        localErrors[key] = `${key} is required`;
        createFormErrors(key, `${key} is required`);
      }
    });

    setFormErrors(localErrors);

    return {
      localErrors,
      hasErrors: Object.keys(localErrors).length > 0,
    };
  };

  const createFormErrors = (key, value) => {
    setFormErrors({
      ...formErrors,
      [key]: value,
    });
  };

  return { formErrors, createFormErrors, checkAndGenerateFormError };
}
