import React from "react";
import { leadsTableHead, skeletonRows } from "./table_schema";
import { Skeleton, TableCell, TableRow, Typography } from "@mui/material";

const SkeletonRows = ({ tableHead }) => {
  return skeletonRows.map((row) => (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.id)}
      role=""
      tabIndex={-1}
      key={row.id}
      sx={{ cursor: "pointer" }}
    >
      {tableHead.map((row2) => (
        <TableCell align="right" key={row2.id}>
          <Skeleton animation="wave">
            <Typography variant="h6">{row2.id}</Typography>
          </Skeleton>
        </TableCell>
      ))}
    </TableRow>
  ));
};

export default SkeletonRows;
