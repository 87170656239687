import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import api from "../../API/api";

import { useNavigate } from "react-router-dom";

const DashboardPage = () => {

    const navigation = useNavigate();
    const [count, setCount] = useState();


    useEffect(() => {
        getCounts();

    }, []);

    const getCounts = () => {
        api.getCounts().then((res) => {
            console.log(res.data);
            setCount(res.data)
        })
    }




    return (
        <>
            <div className="containe">

                <div className="text-center">
                    <img src="https://jodiperfect.co/img/logo.png" width={150} alt="" />
                    <h1>
                        Jodi Perfect
                    </h1>
                </div>


                <div className="row">
                    <div className="col-6 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5> Sign Up Data : {count && count.signupcount}</h5>
                            </div>
                        </Paper>
                    </div>
                    <div className="col-6 p-2">
                        <Paper elevation={3}>
                            <div className="p-3">
                                <h5> Schedule Data : {count && count.schedulecount}</h5>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardPage;