import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddTaskIcon from "@mui/icons-material/AddTask";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GradingIcon from "@mui/icons-material/Grading";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import ContactsIcon from "@mui/icons-material/Contacts";

import ApartmentIcon from "@mui/icons-material/Apartment";

import { InputBase, styled, Menu, MenuItem } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LeaderboardSharpIcon from "@mui/icons-material/LeaderboardSharp";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AdbIcon from "@mui/icons-material/Adb";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AuthUser from "../../API/token";

import { useNavigate, Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { PartnersContextProvider } from "../../context/PartnersContext";
import { CoordinatorsContextProvider } from "../../context/CoordinatorContext";
import { LeadsContextProvider } from "../../context/LeadsContext";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import {
  CommissionsContext,
  CommissionsContextProvider,
} from "../../context/CommissionContext";
import { useUserContext } from "../../context/UserContext";
import AdminDrawer from "./AdminDrawer";
import CoordinatorDrawer from "./CoordinatorDrawer";
// import AuthUser from '../../API/token';

const drawerWidth = 240;

const SearchBar = styled("div")(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "40%",
}));

const settings = ["Profile", "Account", "Dashboard", "Logout"];
const pages = [];

const Dashboard = (props) => {
  const { token, logout, user } = AuthUser();

  const { user: authUser } = useUserContext();

  const logoutuser = () => {
    // if (token != undefined) {
    //   logout();
    // }
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // ***************

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(false);
  const [openPartners, setOpenPartners] = React.useState(false);
  const [openCoordinators, setOpenCoordinators] = React.useState(false);
  const [openLeads, setOpenLeads] = React.useState(false);

  const [openBlog, setBlogOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickPartners = () => {
    setOpenPartners(!openPartners);
  };

  const handleClickCoordinators = () => {
    setOpenCoordinators(!openCoordinators);
  };

  const handleClickLeads = () => {
    setOpenLeads(!openLeads);
  };

  const blogClick = () => {
    setBlogOpen(!openBlog);
  };

  const [open2, setOpen2] = React.useState(true);

  const drawer = (
    <div>
      <Toolbar>
        <div>
          {/* <img src="/logo.png" className='img-fluid' /> */}
          {/* ****************************************** */}
          {!open2 ? (
            <MenuIcon onClick={() => setOpen2(true)} />
          ) : (
            <h2>
              Jodi Perfect <MenuIcon onClick={() => setOpen2(false)} />
            </h2>
          )}
          {/* ******************************************           */}
        </div>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding onClick={() => navigate("/admin/dashboard")}>
          <ListItemButton>
            <ListItemIcon>
              <LeaderboardSharpIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => navigate("/admin/SignUpData")}>
          <ListItemButton>
            <ListItemIcon>
              <BookOnlineIcon />
            </ListItemIcon>
            <ListItemText>Sign Up Users</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          onClick={() => navigate("/admin/ScheduleData")}
        >
          <ListItemButton>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText>Schedule User</ListItemText>
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding onClick={() => navigate("/admin/Profiles")}>
          <ListItemButton>
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </ListItemButton>
        </ListItem> */}

        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <ContactsIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/Profiles")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Profile" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/SharedProfiles")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Shared Profiles" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickPartners}>
          <ListItemIcon>
            <HandshakeIcon />
          </ListItemIcon>
          <ListItemText primary="Partner" />
          {openPartners ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openPartners} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/partners/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Partners" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/partners/create")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Create Partners" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/partners/commissions")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Commissions" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickCoordinators}>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText primary="Coordinators" />
          {openCoordinators ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openCoordinators} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/coordinators/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Coordinators" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/coordinators/create")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Create Coordinators" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/coordinators/commissions")}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="Commissions" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickLeads}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Leads" />
          {openLeads ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openLeads} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/admin/leads/manage")}
            >
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Leads" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItem disablePadding onClick={() => navigate("/admin/Blogs")}>
          <ListItemButton>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText>Blogs</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <a
            style={{ color: "black" }}
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1LnOlrfPWJ8oAn58borllI3YG73S6Ishj/edit?usp=sharing&ouid=108432051821802881918&rtpof=true&sd=true"
          >
            <ListItemButton>
              <ListItemIcon>
                <GradingIcon />
              </ListItemIcon>
              <ListItemText> Management Exel </ListItemText>
            </ListItemButton>
          </a>
        </ListItem>

        {/* There was commented code here section */}

        <ListItemButton disablePadding onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CoordinatorsContextProvider>
      <PartnersContextProvider>
        <LeadsContextProvider>
          <CommissionsContextProvider>
            <Box sx={{ display: "flex" }}>
              <Toaster
                position="top-right"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                  // Define default options
                  className: "",
                  duration: 2000,
                  style: {
                    background: "#363636",
                    color: "#fff",
                  },

                  // Default options for specific types
                  success: {
                    duration: 2000,
                    theme: {
                      primary: "green",
                      secondary: "black",
                    },
                  },
                }}
              />
              <CssBaseline />
              <AppBar
                position="fixed"
                sx={{
                  //  ***************************************************
                  width: {
                    sm: `calc(100% - ${drawerWidth}px) + ${!open2 ? 180 : 0}`,
                  },
                  //  ***************************************************
                  ml: { sm: `${drawerWidth}px` },
                }}
              >
                <Container maxWidth="xl">
                  <Toolbar disableGutters>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2, display: { sm: "none" } }}
                    >
                      <MenuIcon />
                    </IconButton>

                    <Typography
                      variant="h6"
                      noWrap
                      component="a"
                      // href="/"
                      sx={{
                        mr: 2,
                        display: { xs: "none", md: "flex" },
                        fontFamily: "monospace",
                        fontWeight: 700,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none",
                      }}
                    >
                      {/* Jodi Perfect */}
                      {/* <div>aaaaaaaaaaaakajkjkjkjkjkjkjkjkj</div> */}
                      <MenuIcon />
                    </Typography>

                    <Box
                      sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                    >
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                      >
                        {/* <MenuIcon /> */}
                      </IconButton>

                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                          display: { xs: "block", md: "none" },
                        }}
                      >
                        {pages.map((page) => (
                          <MenuItem key={page} onClick={handleCloseNavMenu}>
                            <Typography textAlign="center">{page}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                    <Typography
                      variant="h5"
                      noWrap
                      component="a"
                      href=""
                      sx={{
                        mr: 2,
                        display: { xs: "flex", md: "none" },
                        flexGrow: 1,
                        fontFamily: "monospace",
                        fontWeight: 700,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none",
                      }}
                    >
                      Jodi Perfect
                    </Typography>
                    <Box
                      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    >
                      {pages.map((page) => (
                        <Button
                          key={page}
                          onClick={handleCloseNavMenu}
                          sx={{ my: 2, color: "white", display: "block" }}
                        >
                          {page}
                        </Button>
                      ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar
                            alt="Remy Sharp"
                            src="/static/images/avatar/2.jpg"
                          />
                        </IconButton>
                      </Tooltip>

                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {settings.map((setting) => (
                          <MenuItem key={setting} onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">
                              {setting}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Toolbar>
                </Container>
              </AppBar>
              <Box
                component="nav"
                // **************************************
                sx={{
                  width: { sm: drawerWidth - `${!open2 ? 180 : 0}` },
                  flexShrink: { sm: 0 },
                }}
                // **************************************
                aria-label="mailbox folders"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                >
                  {authUser.role?.name === "admin" && (
                    <AdminDrawer
                      open2={open2}
                      setOpen2={setOpen2}
                      open={open}
                      handleClick={handleClick}
                      openPartners={openPartners}
                      handleClickPartners={handleClickPartners}
                      openCoordinators={openCoordinators}
                      handleClickCoordinators={handleClickCoordinators}
                      openLeads={openLeads}
                      handleClickLeads={handleClickLeads}
                      logout={logout}
                    />
                  )}

                  {authUser.role?.name === "coordinator" && (
                    <CoordinatorDrawer
                      open2={open2}
                      setOpen2={setOpen2}
                      open={open}
                      handleClick={handleClick}
                      openPartners={openPartners}
                      handleClickPartners={handleClickPartners}
                      openCoordinators={openCoordinators}
                      handleClickCoordinators={handleClickCoordinators}
                      openLeads={openLeads}
                      handleClickLeads={handleClickLeads}
                      logout={logout}
                    />
                  )}
                  {/* {drawer} */}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      // ******************************
                      width: drawerWidth - `${!open2 ? 180 : 0}`,
                      // ********************************
                    },
                  }}
                  open
                >
                  {authUser.role?.name === "admin" && (
                    <AdminDrawer
                      open2={open2}
                      setOpen2={setOpen2}
                      open={open}
                      handleClick={handleClick}
                      openPartners={openPartners}
                      handleClickPartners={handleClickPartners}
                      openCoordinators={openCoordinators}
                      handleClickCoordinators={handleClickCoordinators}
                      openLeads={openLeads}
                      handleClickLeads={handleClickLeads}
                      logout={logout}
                    />
                  )}

                  {authUser.role?.name === "coordinator" && (
                    <CoordinatorDrawer
                      open2={open2}
                      setOpen2={setOpen2}
                      open={open}
                      handleClick={handleClick}
                      openPartners={openPartners}
                      handleClickPartners={handleClickPartners}
                      openCoordinators={openCoordinators}
                      handleClickCoordinators={handleClickCoordinators}
                      openLeads={openLeads}
                      handleClickLeads={handleClickLeads}
                      logout={logout}
                    />
                  )}
                </Drawer>
              </Box>
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  overflow: "auto",
                  p: 3,
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
              >
                <Toolbar />

                <Outlet />
              </Box>
            </Box>
          </CommissionsContextProvider>
        </LeadsContextProvider>
      </PartnersContextProvider>
    </CoordinatorsContextProvider>
  );
};

export default Dashboard;

const CommentedCode = () => {
  {
    /* <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Department" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/admin/addDepartment")}>
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Add Departments" />
            </ListItemButton>

            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/admin/viewDepartment")}>
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="View Departments" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={blogClick}>
          <ListItemIcon>
            <BabyChangingStationIcon />
          </ListItemIcon>
          <ListItemText primary="Doctors" />
          {openBlog ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openBlog} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/admin/addAdminDoctor")}>
              <ListItemIcon>
                <AddTaskIcon />
              </ListItemIcon>
              <ListItemText primary="Add Doctors" />
            </ListItemButton>

            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/admin/viewDoctor")}>
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary="View Doctors" />
            </ListItemButton>
          </List>
        </Collapse>

          <ListItemButton disablePadding onClick={() => navigate("/admin/addServices")}>
            <ListItemIcon>
              <RemoveRedEyeIcon />
            </ListItemIcon>
            <ListItemText>Services</ListItemText>
          </ListItemButton> */
  }
};
