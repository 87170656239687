import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import ScrollableContainer from "../containers/ScrollableContainer";
import dayjs from "dayjs";

import { Skeleton, Stack } from "@mui/material";
import SkeletonRows from "./SkeletonRows";
import TableOptionsMenuGenerator from "./TableOptionsMenuGenerator";
import TableHeadMultiPurpose from "./TableHeadMultiPurpose";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  position: "sticky",
  right: 0,
  zIndex: 2,
  borderLeft: "2px solid black",
  backgroundColor: "white",
}));

/**
 * Renders the head cells
 *
 * @param {Object} props - The properties passed to the component
 * @param {array} props.tableHead - Contains the schema for which table cells will be generated
 *
 * @return {JSX.Element} The enhanced table head component
 */
function TableHeadRow({ tableHead }) {
  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.id === "actions") {
            return (
              <StickyTableCell
                sx={{ width: "10%", zIndex: 5 }}
                key={headCell.id}
                align={headCell.id === "actions" ? "center" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </StickyTableCell>
            );
          }
          return (
            <TableHeadMultiPurpose headCell={headCell} key={headCell.id} />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/**
 * Renders a custom toolbar for an enhanced table.
 * This only prints selected or the main heading now
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.numSelected - The number of selected items.
 * @return {JSX.Element} The rendered toolbar component.
 */
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

/**
 * Currently not functional for other data soon it will be
 *
 * @param {Object} props - The properties passed to the component
 * @param {array} props.tableHead - The table head cells. will be an array from table_schema file
 * @param {array} props.rows - The rows that are going to be rendered by the table. Make sure the proper table head is passed for the rows
 * @param {string} props.title - The title of the table
 * @param {boolean} props.loading - The loading state of the table
 * @param {function} props.paginateFetch - The fetch function for paginating the table
 * @param {array} props.paginateOptions - The options for the pagination
 * @param {array} props.optionsMenu - The options menu for the table
 *
 * @return {JSX.Element} The custom table component
 */
export default function CustomTable({
  tableHead,
  rows,
  title,
  loading,
  paginateFetch,
  paginateOptions,
  optionsMenu,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    paginateFetch(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    paginateFetch(page, parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 1 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        padding: "10px",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: "73vh",
          padding: "10px",
        }}
      >
        <EnhancedTableToolbar numSelected={selected.length} title={title} />
        <ScrollableContainer direction="column">
          <TableContainer sx={{ height: "100%" }}>
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <TableHeadRow rowCount={rows.length} tableHead={tableHead} />
              <TableBody>
                {loading && <SkeletonRows tableHead={tableHead} />}

                {!loading &&
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role=""
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        {tableHead.map((tableCell) => {
                          if (tableCell.id === "actions") {
                            return (
                              <StickyTableCell
                                key={tableCell.id}
                                //   component="th"
                                id={tableCell.id}
                                scope="row"
                                // padding="none"
                                align="center"
                              >
                                <TableOptionsMenuGenerator
                                  optionsMenu={optionsMenu}
                                  optionsFor={row}
                                ></TableOptionsMenuGenerator>
                              </StickyTableCell>
                            );
                          }

                          return (
                            <TableCell
                              key={tableCell.id}
                              //   component="th"
                              id={tableCell.id}
                              scope="row"
                              // padding="none"
                            >
                              <TableCellData tableCell={tableCell} row={row} />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollableContainer>
      </Paper>
      <Stack direction={"row-reverse"} spacing={2}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            paginateOptions
              ? paginateOptions.total
                ? paginateOptions.total
                : 0
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Stack>
    </Box>
  );
}

const TableCellData = ({ tableCell, row }) => {
  let data = row[tableCell.id];

  if (tableCell.format) {
    data = tableCell.format(row[tableCell.id]);
  }

  if (tableCell.isDate) {
    let date = dayjs(data).format("DD/MM/YYYY");
    {
      return (
        <Box>
          <Typography>{date}</Typography>
        </Box>
      );
    }
  }

  return data;
};
