import API from "./apiConfig";

export default {
  getLandingform() {
    return API.get("/getLandingform");
  },
  getSchedule() {
    return API.get("/getSchedule");
  },
  getCounts() {
    return API.get("/getCounts");
  },
  getProfiles() {
    return API.get("/getProfiles");
  },
  searchProfile(search) {
    return API.get(`/searchProfile/${search}`);
  },
  createProfileLink(forWhom, profiles) {
    return API.post("/createProfileLink", { forWhom, profiles });
  },
  sharedProfiles(user) {
    return API.get(`sharedProfiles/${user}`);
  },
  getClientProfile() {
    return API.get(`getClientProfile`);
  },

  profileActiveStatus(id) {
    return API.post("/profileActiveStatus", { id });
  },
  sharedActiveStatus(id) {
    return API.post("/sharedActiveStatus", { id });
  },
  deleteProfile(id) {
    return API.post("/deleteProfile", { id });
  },
  likeProfile(id) {
    return API.post("/likeProfile", { id });
  },
  addNewProfile(data) {
    return API.post("/addNewProfile", { data });
  },
  removeProfileFromShare(data) {
    return API.post("/removeProfileFromShare", { data });
  },

  GetBlogs() {
    return API.get("/GetBlogs");
  },
  updateBlog(data) {
    return API.get("/updateBlog", { data });
  },
  blogActiveStatus(data) {
    return API.post("/blogActiveStatus", { data });
  },

  // login
  register(formData) {
    return API.post("/register", { formData });
  },

  login(email, password, role) {
    return API.post("/login", { email, password, role, category: "crm" });
  },

  // PARTNER registering related
  registerPartner(formData) {
    return API.post("/auth/admin/register/partner", { ...formData });
  },

  fetchPartners(data) {
    return API.get("/admin/partners", { params: { ...data } });
  },

  fetchPartner(data) {
    return API.get("/admin/partner", { params: { ...data } });
  },

  updatePartner(param, data) {
    return API.post(`admin/partners/${param}/update`, { ...data });
  },

  // coordinator registering related
  registerCoordinator(formData) {
    return API.post("/auth/admin/register/coordinator", { ...formData });
  },
  fetchCoordinators(data) {
    return API.get("/admin/coordinators", { params: { ...data } });
  },

  fetchCoordinator(data) {
    return API.get("/admin/coordinator", { params: { ...data } });
  },

  updateCoordinator(paramId, data) {
    return API.post("/admin/coordinators/" + paramId + "/update", { ...data });
  },

  // LEADS
  fetchLeads(data) {
    return API.get("/admin/leads", { params: { ...data } });
  },

  getLeadById(id) {
    return API.get(`/admin/leads/${id}`);
  },

  updateLead(lead_id, data) {
    return API.post(`admin/leads/${lead_id}/update`, { ...data });
  },

  // COMMISSIONS
  fetchCommissions(data) {
    return API.get("/admin/commissions", { params: { ...data } });
  },

  payCommission(data) {
    return API.post("admin/commission/pay", {
      ...data,
    });
  },

  adsLeads(data) {
    return API.get(`crm/onboarders/all`, {
      params: {
        ...data
      }
    });
  },

};
