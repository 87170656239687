import { Grid, MenuItem, TextField } from "@mui/material";
import React from "react";

/**
 * @typedef {Object} GridTextField
 * @property {string} id
 * @property {string} label
 * @property {string} field_type
 * @property {number} xs
 * @property {number} md
 * @property {number} lg
 * @property {string} defaultValue
 * @property {function} onChange
 * @property {boolean} disabled
 * @returns
 */

/**
 *
 * @typedef {GridTextField[]} GridTextFields
 */

/**
 * @typedef {Object} GridTextFieldGeneratorProps
 * @property {GridTextFields} fields
 * @property {Object} form
 */

const SelectTextField = ({ field, options, form }) => {
  return (
    <TextField
      fullWidth
      id={field.id}
      label={field.label}
      variant="outlined"
      value={form[field.id]}
      defaultValue={field.defaultValue ? field.defaultValue : ""}
      onChange={(e) => {
        field.onChange(e.target.value);
      }}
      select
      disabled={field.disabled}
    >
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.key}
        </MenuItem>
      ))}
    </TextField>
  );
};

const TextTextField = ({ field, form }) => {
  return (
    <TextField
      fullWidth
      type={field.field_type}
      id={field.id}
      label={field.label}
      variant="outlined"
      value={form[field.id]}
      onChange={(e) => field.onChange(e.target.value)}
      disabled={field.disabled}
    />
  );
};

/**
 * @function
 * @param {GridTextFieldGeneratorProps}
 * @property {GridTextFields[]} props.fields
 * @returns
 */
const GridTextFieldGenerator = ({ fields, form }) => {
  console.log("what the form ", form);

  return (
    <Grid container spacing={2}>
      {fields.map((field) => {
        return (
          <Grid
            item
            xs={field.xs || 12}
            md={field.md || 12}
            lg={field.lg || 12}
            key={field.id}
          >
            {field.field_type === "select" && (
              <SelectTextField
                field={field}
                options={field.options}
                form={form}
              />
            )}
            {field.field_type === "text" && (
              <TextTextField field={field} form={form} />
            )}
            {field.field_type === "number" && (
              <TextTextField field={field} form={form} />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GridTextFieldGenerator;
