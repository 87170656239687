import React, { useEffect } from "react";
import { useCoordinatorContext } from "../../context/CoordinatorContext";
import CustomTable from "../../components/table/CustomTable";
import { useCommissionsContext } from "../../context/CommissionContext";

const PartnersCommissionsPage = () => {
  const {
    commissions,
    commissionsFetch,
    commissionsFetchPaginate,
    commissionsLoading,
    optionsMenu,
    commissionsTableHead,
    paginateOptions,
    resetLoading,
  } = useCommissionsContext();

  let tableHead = commissionsTableHead;

  useEffect(() => {
    commissionsFetch({
      params: {
        commissions_for: "partner",
      },
    });
  }, []);

  tableHead = tableHead.filter((head) => {
    if (head.id === "no_of_partners_listed") return false;

    return true;
  });

  const paginateFetch = (page, totalRows) => {
    commissionsFetchPaginate({
      params: {
        page: page,
        total_rows: totalRows,
        commissions_for: "partner",
      },
    });
  };

  return (
    <CustomTable
      loading={commissionsLoading}
      optionsMenu={optionsMenu}
      paginateFetch={paginateFetch}
      paginateOptions={paginateOptions}
      rows={commissions}
      tableHead={tableHead}
      title="Commissions for partners"
    ></CustomTable>
  );
};

export default PartnersCommissionsPage;
