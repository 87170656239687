import React, { useEffect } from "react";
import CustomTable from "../../components/table/CustomTable";
import { leadsTableHead } from "../../components/table/table_schema";
import { usePartnersContext } from "../../context/PartnersContext";
import CoordinatorManageLeadsPage from "./coordinators.leads.manage";
import { useParams } from "react-router-dom";

const CoordinatorPartnersManagePage = () => {
  const {
    partners,
    partnersPaginate,
    partnersTableHead,
    partnersFetch,
    partnersLoading,
    partnersFetchPaginate,
    paginateOptions,
    optionsMenu,
  } = usePartnersContext();

  const params = useParams();

  let coordinatorId = params.coordinatorId;

  useEffect(() => {
    partnersFetch(coordinatorId);
  }, [coordinatorId]);

  return (
    <CustomTable
      rows={partners}
      tableHead={partnersTableHead}
      title="Partners"
      loading={partnersLoading}
      paginateFetch={partnersFetchPaginate}
      paginateOptions={paginateOptions}
      optionsMenu={optionsMenu}
    ></CustomTable>
  );
};

export default CoordinatorPartnersManagePage;
